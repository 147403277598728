

import React from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
// import { Camera } from 'react-camera-pro';


import { AuthProvider } from "../contexts/FirebaseAuth"
import { FlashProvider } from "../contexts/FlashContext"
import ProtectedRoute from "./ProtectedRoute"

import FlashContainer from "./FlashContainer"
import TopNav from "./TopNav"
//import Footer from "./Footer"

// import Home from "./Home"
import Footer from "./Footer"
// import Products from "./Products"
// import Pricing from "./Packages"
import Shopping from "./Shopping"
// import Camera from "./Camera"
import Gallery from "./Gallery3"
import GalleryFilter from "./GalleryFilter"
// import GallerySFW from "./GallerySFW"
// import Timeline from "./Timeline"
// import RoomGallery from "./RoomGallery"
import SyncAlbums from "./SyncAlbums"
import SyncAlbums2 from "./SyncAlbums2"
// import PartyAlbums from "./PartyAlbums"
// import PartyAlbums2 from "./PartyAlbums2"
import PartyAlbums from "./PartyAlbums3"
import PopUpVideo from "./PopUpVideo"
// import PartyAlbums from "./PartyAlbumsSFW"
// import Images from "./Images"
// import SplatCamOLD from "./SplatCam"
// import SplatCam2OLD from "./SplatCam2"
// import SplatCam3OLD from "./SplatCam3"
// import SplatCam4OLD from "./SplatCam4"
import SplatCam from "./SplatCam5"
import PotatoCam from "./PotatoCam"
import RetroCam from "./RetroCam"
// import PropCam from "./PropCamera"
import AlbumCam from "./AlbumCamera"
// import Staging from "./SplatCamHQ"
import About from "./AboutNew"
import Contact from "./Contact"
import Replies from "./ContactReply"
import Terms from "./Terms"
import Privacy from "./Privacy"
import Jargon from "./Jargon"
// import SignUp from "./SignUp"
// import Share from "./Share"
import Landing from "./Landing3"
// import uloe from "./uloe"
// import LandingTest from "./Landing4"
// import MapsTest from "./MapsTest"
// import Orbit from "./OrbitGame"
// import Landstage from "./Landing3"
// import SockMon from "./SocketDBMonitor"
// import Login from "./GoogleLogin"
// import Login from "./GoogleLoginREDIR"
import Login from "./LoginCombo"
// import UserProfile from "./Profile"
import UserProfile2 from "./Profile2"
import AlertMe from "./AlertMe" // example page for seeing different alert styling options
import FlashForm from "./FlashForm" // example page for testing and debugging the flash
import NotFoundComponent from './404notfound'; // experimental custom 404 page

import 'bootstrap/dist/css/bootstrap.min.css'
// import './MORPHbootstrap.min.css'
import './LUXbootstrap.min.css'
// import './QUARTZbootstrap.min.css'
import './customStyles.css'

const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID
const GA_DEBUG_MODE = (process.env.REACT_APP_GA_DEBUG_MODE === "true")
ReactGA.initialize(GA_TRACKER_ID, {debug: GA_DEBUG_MODE})

export default function App() {
    ReactGA.pageview(window.location.href)

    return (
        <AuthProvider>
        <FlashProvider>
            <div className="App">
                <TopNav/>

                <Container fluid className="px-0">
                    <FlashContainer/>

                    <Router>
                        <Switch>
                            <Route exact path="/" component={Landing} />                            
                            
                            <Route exact path="/about" component={About} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/terms" component={Terms} />
                            <Route exact path="/privacy" component={Privacy} />
                            <Route exact path="/jargon" component={Jargon} />
                            <Route exact path="/login" component={Login} />
                            {/* 
                            <Route exact path="/products" component={Products} />
                            <Route exact path="/landstage" component={LandingTest} />
                            <ProtectedRoute exact path="/camera" component={Camera} />
                             */}
                            <ProtectedRoute exact path="/profile" component={UserProfile2} />
                            <ProtectedRoute exact path="/dear-john" component={Replies} />
                            <ProtectedRoute exact path="/idkfa" component={Gallery} />
                            <ProtectedRoute exact path="/iddqd" component={GalleryFilter} />
                            <ProtectedRoute exact path="/nsync" component={SyncAlbums} />
                            <ProtectedRoute exact path="/nsync2" component={SyncAlbums2} />
                            <ProtectedRoute exact path="/album" component={PartyAlbums} />
                            <ProtectedRoute exact path="/pov" component={PopUpVideo} />
                            <ProtectedRoute exact path="/album-ar" component={AlbumCam} />
                            <ProtectedRoute exact path="/splat" component={SplatCam} />
                            <ProtectedRoute exact path="/potato" component={PotatoCam} />
                            <ProtectedRoute exact path="/retro" component={RetroCam} />
                            
                            <ProtectedRoute exact path="/shopping" component={Shopping} />
                            
                            {/* <ProtectedRoute exact path="/landing" component={Landing} />
                            <ProtectedRoute exact path="/prop" component={PropCam} />
                            <ProtectedRoute exact path="/uloe" component={uloe} />
                            <ProtectedRoute exact path="/test-nsfwjs" component={GallerySFW} />
                            <ProtectedRoute exact path="/staging" component={Staging} />
                            <ProtectedRoute exact path="/signing" component={SignUp} />
                            <ProtectedRoute exact path="/splatnew" component={SplatCamNEW} />
                            <ProtectedRoute exact path="/login-test" component={LoginCombo} />
                            <ProtectedRoute exact path="/timeline" component={Timeline} />
                            <ProtectedRoute exact path="/carmensandiego" component={MapsTest} />
                            <ProtectedRoute exact path="/og" component={Home} />
                            <ProtectedRoute exact path="/pricing" component={Pricing} />
                            <ProtectedRoute exact path="/orbit" component={Orbit} />
                            <ProtectedRoute exact path="/sockmon" component={SockMon} />
                            <ProtectedRoute exact path="/gallery-dev" component={RoomGallery} /> */}
                            

                            <Route path="/alerts" component={AlertMe} />
                            <Route path="/flashes" component={FlashForm} />
                            <Route path="*" component={NotFoundComponent} />
                        </Switch>
                    </Router>
                </Container>

                {/* <Footer/> */}
                <Footer />
            </div>
        </FlashProvider>
        </AuthProvider>
    )
}
