import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

const SyncStream2 = ({ roomCode, keyCode }) => {
  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [syncIds, setSyncIds] = useState([]);

  const fetchImages = useCallback(async () => {
    const photosRef = firebase.database().ref('photos').orderByChild('roomCode').equalTo(roomCode);
    const snapshot = await photosRef.once('value');
    if (snapshot.exists()) {
      const photos = snapshot.val();
      const syncIdSet = new Set();
      const imageList = [];

      Object.values(photos).forEach(photo => {
        syncIdSet.add(photo.syncId);
        if (photo.syncId) {
          imageList.push(photo);
        }
      });

      // Sort images by newest first
      const sortedImages = imageList.sort((a, b) => b.timestamp - a.timestamp);
      setSyncIds([...syncIdSet].slice(0, 12));
      setImages(sortedImages);
    }
  }, [roomCode, keyCode]);

  useEffect(() => {
    fetchImages();

    const photosListener = firebase.database().ref('photos').orderByChild('roomCode').equalTo(roomCode);
    photosListener.on('child_added', fetchImages);

    return () => {
      photosListener.off('child_added', fetchImages);
    };
  }, [fetchImages, roomCode, keyCode]);

  const openLightbox = useCallback((syncId) => {
    const relatedImages = images.filter(image => image.syncId === syncId);
    setLightboxImages(relatedImages);
    setSelectedImage(relatedImages[0]?.url);
    setShowLightbox(true);
  }, [images]);

  const closeLightbox = () => setShowLightbox(false);

  const LightboxModal = () => (
    <ResponsiveModal open={showLightbox} onClose={closeLightbox} center styles={{ modal: { maxWidth: '80%', maxHeight: '80vh' } }}>
      <div className="lightbox-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img
          src={selectedImage}
          alt="Selected"
          style={{ maxHeight: '70vh', maxWidth: '100%', objectFit: 'contain' }}
        />
        <div className="filmstrip" style={{ display: 'flex', overflowX: 'auto', padding: '10px 0' }}>
          {lightboxImages.map((image, index) => (
            <img
              key={index}
              src={image.url}
              alt={`Synced Image ${index + 1}`}
              className={image.url === selectedImage ? 'selected' : ''}
              onClick={() => setSelectedImage(image.url)}
              style={{ maxHeight: '80px', marginRight: '10px', cursor: 'pointer', objectFit: 'cover' }}
            />
          ))}
        </div>
      </div>
    </ResponsiveModal>
  );

  return (
    <>
      <Row>
        {syncIds.slice().reverse().map(syncId => (
          <Col key={syncId} xs={3} sm={3} md={2} lg={1} xl={1} className="mb-3">
            <Carousel interval={Math.random() * (5000 - 3000) + 3000} fade controls={false} indicators={false}>
              {images
                .filter(image => image.syncId === syncId)
                .map((img, index) => (
                  <Carousel.Item key={index} as="div" className="shadow">
                    <img 
                      src={img.url} 
                      alt={`img-${syncId}-${index}`} 
                      className="d-block w-100 bg-light bg-gradient border-dark shadow pb-4 px-1 pt-1 rounded-top" 
                      style={{ aspectRatio: '1' }}
                      onClick={() => openLightbox(syncId)}
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Col>
        ))}
      </Row>
      <LightboxModal />
    </>
  );
};

export default SyncStream2;
