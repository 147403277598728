import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [currentUserSyncIds, setCurrentUserSyncIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchUserImages = async () => {
      const userId = firebase.auth().currentUser.uid;
      const snapshot = await firebase.database().ref('photos').orderByChild('uid').equalTo(userId).once('value');
      const data = snapshot.val();
      if (data) {
        const userImages = Object.values(data);
        const syncIds = [...new Set(userImages.map(image => image.syncId))];
        setCurrentUserSyncIds(syncIds);
      }
    };

    fetchUserImages();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      if (currentUserSyncIds.length > 0) {
        const snapshot = await firebase.database().ref('photos').orderByChild('syncId').once('value');
        const allData = snapshot.val();
        if (allData) {
          const filteredImages = Object.entries(allData)
            .filter(([key, value]) => currentUserSyncIds.includes(value.syncId))
            .map(([key, value]) => ({ key, ...value }));
          setImages(filteredImages);
        }
      }
    };

    fetchImages();
  }, [currentUserSyncIds]);

  const openLightbox = (syncId) => {
    const relatedImages = images.filter(image => image.syncId === syncId);
    setLightboxImages(relatedImages);
    setSelectedImage(relatedImages[0]?.url);
    setShowLightbox(true);
  };

  const selectImage = (url) => {
    setSelectedImage(url);
  };

  const paginatedSyncIds = currentUserSyncIds.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>

    <Container className="mt-3">
      <Row>
        {paginatedSyncIds.map(syncId => {
          const relatedImages = images.filter(image => image.syncId === syncId).sort((a, b) => a.timestamp - b.timestamp);
          return (
            <Col key={syncId} sm={1} xs={2} className="mb-3">
              <Carousel interval={Math.random() * (5000 - 3000) + 1000} fade controls={false} indicators={false} >
                {relatedImages.map((img, index) => (
                  <Carousel.Item key={img.key} controls={false} indicators={false} as="div">
                    <img 
                      src={img.url} 
                      alt={`img-${syncId}-${index}`} 
                      className="d-block w-100" 
                      style={{ aspectRatio: '4/3' }}
                      onClick={() => openLightbox(syncId)}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          );
        })}
      </Row>
      {/* Pagination Logic Here */}
      <ResponsiveModal open={showLightbox} onClose={() => setShowLightbox(false)} center closeOnEsc closeOnOverlayClick styles={{ modal: { zIndex: 1050 } }}>
        <img src={selectedImage} alt="Selected" style={{ maxHeight: '80vh', maxWidth: '100%' }} />
        <div style={{ display: 'flex', overflowX: 'auto', marginTop: '10px' }}>
          {lightboxImages.map(({ key, url }) => (
            <img 
              key={key} 
              src={url} 
              alt={`img-${key}`} 
              style={{ maxHeight: '100px', maxWidth: '100px', margin: '0 5px', cursor: 'pointer' }} 
              onClick={() => selectImage(url)} 
            />
          ))}
        </div>
      </ResponsiveModal>
    </Container>

    </>
  );
};

export default GalleryComponent;