// Updated React Component Code with Enhanced Room and Key Security
import React, { useRef, useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Form, ListGroup, Container } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import Highlights from './SyncHighlight';
import Share from './Share';
import SyncStream2 from './SyncStream2';

const CameraComponent = () => {
  const videoRef = useRef(null);
  const flashRef = useRef(null);
  const socket = useRef(null);
  const [roomCode, setRoomCode] = useState('');
  const [key, setKey] = useState('');
  const [syncId, setSyncId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [activeUsers, setActiveUsers] = useState(0);
  const [photoNotification, setPhotoNotification] = useState('📷');
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [hasHotPotato, setHasHotPotato] = useState(false);
  const albumUrl = `/album?room=${roomCode}&key=${key}`;
  const roomType = 'potato';

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get('room') || uuidv4().substr(0, 6);
    const key = urlParams.get('key') || uuidv4().substr(0, 6);
    setRoomCode(room);
    setKey(key);
    window.history.replaceState(null, '', `?room=${room}&key=${key}`);
    // Firebase: Save roomCode and key
    // const roomsRef = firebase.database().ref(`rooms/${firebase.auth().currentUser.uid}/${room}`);
    const roomRef = firebase.database().ref(`rooms/${firebase.auth().currentUser.uid}/${room}`);

    roomRef.once('value', snapshot => {
        if (snapshot.exists()) {
        // Room exists, only update dateLastUsed
        roomRef.update({ dateLastUsed: firebase.database.ServerValue.TIMESTAMP });
        } else {
        // Room does not exist, set both dateCreated and dateLastUsed
        roomRef.set({
            key: key,
            dateCreated: firebase.database.ServerValue.TIMESTAMP,
            dateLastUsed: firebase.database.ServerValue.TIMESTAMP
        });
        }
    });
  }, []);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const constraints = { video: { facingMode: 'environment', width: { ideal: 4096 }, height: { ideal: 2160 } } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      } catch (err) {
        console.error("Error accessing the device's camera", err);
      }
    };

    getMedia();

    // Setup WebSocket connection
    // socket.current = io(`https://splatsocket.redradar.dev/${roomCode}/${key}`, { withCredentials: true }); // PROD @ AWS (Regular)
    socket.current = io(`https://potatosocket.redradar.dev/${roomCode}/${key}`, { withCredentials: true }); // PROD @ AWS (Potato)
    // socket.current = io(`https://splatsocket-dev3.redradar.dev/${roomCode}/${key}`, { withCredentials: true }); // DEV @ RR-SPACEBOOK

    socket.current.on('updateUserList', (users) => {
      setConnectedUsers(users);
    });

    socket.current.on('takePhoto', (syncId) => takePhoto(syncId));
    socket.current.on('connect', () => {
      setIsSocketConnected(true);
      socket.current.emit('joinRoom', { userId: firebase.auth().currentUser.uid });
    });
    socket.current.on('passHotPotato', (clientId) => {
      setHasHotPotato(firebase.auth().currentUser.uid === clientId);
    });
    socket.current.on('disconnect', () => setIsSocketConnected(false));
    socket.current.on('updateActiveUsers', (count) => setActiveUsers(count));
    socket.current.on('triggerCountdown', (countdown) => {
      setPhotoNotification(countdown.toString());
      setIsCountdownActive(countdown > 0);
      if (countdown === 0) {
        setTimeout(() => {
          setPhotoNotification('📷');
          setIsCountdownActive(false);
        }, 1000);
      }
    });
    
    return () => {
      socket.current.emit('leaveRoom', roomCode);
      socket.current.disconnect();
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [roomCode, key]);

  if (!firebase.apps.length) {
    firebase.initializeApp({ /* Firebase config */ });
  }

  const takePhoto = async (syncId) => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
    const photo = canvas.toDataURL('image/jpeg');

    const storageRef = firebase.storage().ref();
    const photoRef = storageRef.child(`photos/${roomCode}/${syncId}/${firebase.auth().currentUser.uid}/${Date.now()}.jpg`);
    await photoRef.putString(photo, 'data_url');
    const url = await photoRef.getDownloadURL();

    // Trigger screen flash
    flashRef.current.style.display = 'block';
    setTimeout(() => flashRef.current.style.display = 'none', 100);

    firebase.database().ref('photos').push({
      uid: firebase.auth().currentUser.uid,
      url: url,
      syncId: syncId,
      roomCode: roomCode,
      keyCode: key,
      camera: roomType,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });

    socket.current.emit('photoTaken', { clientId: firebase.auth().currentUser.uid, url: url });
  };

  const triggerShutterSync = () => {
    // Trigger Shutter Sync only if client has the "hot potato"
    if (hasHotPotato) {
      const newSyncId = uuidv4();
      setSyncId(newSyncId);
      socket.current.emit('triggerShutterSync', { roomCode, syncId: newSyncId });
    }
  };

  useEffect(() => {
    socket.current.on('passHotPotato', (clientId) => {
      setHasHotPotato(firebase.auth().currentUser.uid === clientId);
    });
  }, []);

  const handleJoinRoom = (e) => {
    e.preventDefault();
    setShowModal(false);
    const formData = new FormData(e.target);
    const room = formData.get('room');
    const key = formData.get('key');
    setRoomCode(room);
    setKey(key);
    window.history.replaceState(null, '', `?room=${room}&key=${key}`);
  };

  const fetchUserDetails = async (userIds) => {
    const usersDetails = await Promise.all(userIds.map(async (userId) => {
      const userRef = firebase.database().ref(`/users/${userId}`);
      const snapshot = await userRef.once('value');
      return { userId, ...snapshot.val() };
    }));
    setConnectedUsers(usersDetails);
  };
  
  useEffect(() => {
    if (connectedUsers.length > 0) {
      fetchUserDetails(connectedUsers);
    }
  }, [connectedUsers]);

  return (
    <div>
        {/* Screen Flash */}
        <div ref={flashRef} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', backgroundColor: 'white', display: 'none', zIndex: 9999 }}></div>
      {/* Video and Buttons */}
      <Container>
      <Row className="my-2 mx-0 text-center">
        {/* Video */}
        <Col className="col-12 px-0">
          <video ref={videoRef} style={{ width: '100%', objectFit: 'cover' }} autoPlay muted playsInline></video>
        </Col>
      </Row>
      <Row className="my-1 mx-0 text-center d-flex align-items-center">
        {/* Hot Potato Indicator */}
        {hasHotPotato && (
          <Col className="col-12 ">
            {/* <span role="img" aria-label="hot-potato">🥔</span>
            <p>Hot Potato! Press the button to take a photo.</p> */}
            <Row className="my-1 mx-0 text-center d-flex align-items-center">
              <Col className="col-2 px-2">
                <span role="img" aria-label="hot-potato">🥔</span>
              </Col>
              {/* Active User Count */}
              <Col className="col-10 px-2 bg-danger py-2">
                <h5><span className="text-light">You have the Hot Potato!</span></h5>
              </Col>
            </Row>
          </Col>
        )}
        {/* Shutter Sync Button */}
        <Col className="col-4 col-sm-3 pr-0 pl-1 text-center">
          <Button href={albumUrl} variant="info" className="btn btn-info shadow text-center" style={{ width: '100%', fontSize: '1.5em', marginTop: '0.5em'}}><strong>Go to Album</strong></Button>
        </Col>
        <Col className="col-8 col-sm-9 pr-1 pl-4 text-center">
          {/* <Button className="btn btn-outline-info" onClick={triggerShutterSync} style={{ width: '100%', marginTop: '0.5em', fontSize: '1.5em'}}>Shutter Sync</Button> */}

          {!isCountdownActive ? (
            <Button className="btn btn-success shadow" variant="success" onClick={triggerShutterSync} style={{ width: '100%', marginTop: '0.5em', fontSize: '1.5em' }}>Press to Take a Photo</Button>
          ) : (
            <Button className="btn btn-danger shadow" variant="danger" disabled style={{ width: '100%', marginTop: '0.5em', fontSize: '1.5em' }}>Countdown Started...</Button>
          )}
        </Col>
      </Row>
      <Row className="my-1 mx-0 text-center d-flex align-items-center">
        {/* Connection Status */}
        <Col className="col-4 px-2">
          <div style={{ width: '25px', height: '25px', borderRadius: '50%', backgroundColor: isSocketConnected ? 'green' : 'red', margin: 'auto' }}></div>
        </Col>
        {/* Active User Count */}
        <Col className="col-4 px-2 text-success py-2">
          <h5><span className="text-dark">In Party:</span></h5> <h3>{activeUsers.toString().padStart(3, '0')}</h3>
        </Col>
        {/* Photo Notification */}
        <Col className="col-4 px-2 text-success">
          <h1>{photoNotification}</h1>
        </Col>
      </Row>
      <Row className="my-2 mx-0 text-center">
        <Col className="col-12 px-0">
          <SyncStream2 roomCode={roomCode} keyCode={key}/>
        </Col>
      </Row>
      {/* QR Code and Join Room Modal */}
      <Row className="my-2 mx-0 text-center">
        <Col className="col-12 col-md-4 my-2">
          {/* <h5>Connected Users</h5>
            <ListGroup style={{ overflowY: 'scroll', maxHeight: '200px' }}>
              {connectedUsers.map(user => (
                <ListGroup.Item key={user.userId}>
                  <img src={user.profilePic} alt="profile" style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }} />
                  {user.email}
                </ListGroup.Item>
              ))}
              </ListGroup> */}
              <div className="card bg-success text-light px-2 mb-0">
              <div className="card-header text-light"><h4>What Is This?</h4></div>
              <div className="card-body">
                <h5 className="card-title text-dark">How Do I Use this?</h5>
                <div className="card-text text-left">
                  <ol>
                    <li>Start, Join, or Create a Room</li>
                    <li>Share the QR or Room Codes</li>
                    <li>Press the GREEN photo button</li>
                    <li>Wait for the countdown to finish</li>
                    <li>See your latest photos below</li>
                  </ol>
                </div>
                <h5 className="card-title text-dark">OK, Then What?</h5>
                <div className="card-text text-left">
                  <ol>
                    <li>Share with your friends</li>
                    <li>Compare your latest shots</li>
                    <li>Have some fun with it!</li>
                    <li>Show support. Buy me a coffee.</li>
                  </ol>
                </div>
                <h6 className="card-title mt-1 text-dark">Thanks for checking us out!</h6>
              </div>
              {/* <Button variant="info" onClick={() => setShowModal(true)} style={{ width: '100%', fontSize: '1.5em', marginBottom: '0.5em'}}>Share With Friends</Button> */}
              <Share roomCode={roomCode} keyCode={key} /> {/* Pass "key" as "keyCode" */}
            </div>
        </Col>
        <Col className="col-12 col-md-4 my-2 mobile-first">
          <div className="card bg-dark text-light px-2 mb-0">
            <div className="card-header text-warning"><h4>Add your friends</h4></div>
            <div className="card-body">
              <h5 className="card-title">Share this QR Code</h5>
              <div className="card-text">
                {/* <QRCode value={`${window.location.origin}${window.location.pathname}?room=${roomCode}&key=${key}`} size={240} level={"H"} includeMargin={true} /><br /> */}
                <QRCode 
                  value={`${window.location.origin}${window.location.pathname}?room=${roomCode}&key=${key}`}
                  size={240}
                  bgColor={"#f6f6f6"}
                  fgColor={"#0b0b0b"}
                  level={"H"}
                  includeMargin={true}
                  imageSettings={{
                    src: "https://partypics.zip/logo512.png",
                    x: undefined,
                    y: undefined,
                    height: 48,
                    width: 48,
                    excavate: true,
                  }}
                /><br />
                {/* Scan the QR code or enter room and key manually. */}
                <h5 className="card-title">Share These Codes</h5>
                <Row className="my-2 mx-0 text-center">
                  <Col className="col-6 mx-0 px-0">
                  <h5>Room: <span className="text-warning">{roomCode}</span></h5>
                  </Col>
                  <Col className="col-6 mx-0 px-0">
                  <h5>Key: <span className="text-warning">{key}</span></h5>
                  </Col>
                </Row>
              </div>
            </div>
            <Button variant="warning" onClick={() => setShowModal(true)} style={{ width: '100%', fontSize: '1.5em', marginBottom: '0.5em'}}>Join a Room</Button>
          </div>
        </Col>
        <Col className="col-12 col-md-4 my-2">
          <div className="card bg-info text-light px-2 mb-0">
              <div className="card-header text-dark"><h4>Most Recent Photo</h4></div>
              <div className="card-body">
                <h5 className="card-title">This should look familiar...</h5>
                <div className="card-text">
                  <Highlights />
                  <h5 className="card-title mt-1 text-dark">Click to see other views</h5>
                  <h5>Want to see more?</h5>
                </div>
              </div>
              <a href="/profile" className="btn btn-success shadow-sm" style={{ width: '100%', fontSize: '1.5em', marginBottom: '0.5em'}}><strong>Manage Your Photos</strong></a>
            </div>
        </Col>
      </Row>
      </Container>
      {/* Join Room Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Join a Room</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleJoinRoom}>
          <Modal.Body>
            <Form.Group controlId="formRoomCode">
              <Form.Label>Room Code</Form.Label>
              <Form.Control type="text" placeholder="Enter room code" name="room" required />
            </Form.Group>
            <Form.Group controlId="formKey">
              <Form.Label>Key</Form.Label>
              <Form.Control type="text" placeholder="Enter key" name="key" required />
            </Form.Group>
            <span className="text-warning text-right">LETTERS & NUMBERS ONLY</span><br />
            <span className="text-warning text-end">CODES & KEYS HAVE 6-CHARACTERS</span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" type="submit">Join Room</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CameraComponent;
