// Enhanced GalleryComponent with RoomCode and Key Input Fields for Dynamic Image Loading

import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Container, Row, Col, Carousel, Form, Button, Pagination } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
import { DownloadModal } from './ImageDownload';

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [roomCode, setRoomCode] = useState('');
  const [key, setKey] = useState('');
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const albumUrl = `/album?room=${roomCode}&key=${key}`;
  const backUrl = `/splat?room=${roomCode}&key=${key}`;
  const retroUrl = `/retro?room=${roomCode}&key=${key}`;
  const potatoUrl = `/potato?room=${roomCode}&key=${key}`;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 16;

  // Pagination logic
  const paginatedImages = useMemo(() => {
    const startIndex = (currentPage - 1) * imagesPerPage;
    return images.slice(startIndex, startIndex + imagesPerPage);
  }, [currentPage, images]);

  const fetchImages = async (room, keyCode) => {
    const snapshot = await firebase.database().ref('photos')
      .orderByChild('roomCode')
      .equalTo(room)
      .once('value');

    const allData = snapshot.val();
    if (allData) {
      const filteredImages = Object.entries(allData)
        .filter(([_, value]) => value.keyCode === keyCode)
        .map(([key, value]) => ({ key, ...value }));
      setImages(filteredImages);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get('room') || '';
    const keyCode = urlParams.get('key') || '';
    setRoomCode(room);
    setKey(keyCode);

    if (room && keyCode) {
      fetchImages(room, keyCode);
    }
  }, []);

  const handleRoomChange = (e) => {
    setRoomCode(e.target.value);
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchImages(roomCode, key);
  };

  const openLightbox = (syncId) => {
    const relatedImages = images.filter(image => image.syncId === syncId);
    setLightboxImages(relatedImages);
    setSelectedImage(relatedImages[0]?.url);
    setShowLightbox(true);
  };

  const selectImage = (url) => {
    setSelectedImage(url);
  };

  const handleJoinRoom = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    history.push(`/splat?room=${roomCode}&key=${key}`);
  };

  const handleShowModal = () => setShowModal(true);

  const uploadImages = async (files) => {
    const syncId = uuidv4();
    const promises = [];
    for (const file of files) {
      const photoRef = firebase.storage().ref(`photos/${roomCode}/${syncId}/${firebase.auth().currentUser.uid}/${file.name}`);
      const promise = photoRef.put(file)
        .then(async () => {
          const url = await photoRef.getDownloadURL();
          return firebase.database().ref('photos').push({
            uid: firebase.auth().currentUser.uid,
            url: url,
            syncId: syncId,
            roomCode: roomCode,
            keyCode: key,
            timestamp: firebase.database.ServerValue.TIMESTAMP
          });
        });
      promises.push(promise);
    }

    await Promise.all(promises);
    // Refresh images after upload
    fetchImages(roomCode, key);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    uploadImages(files);
  };

  // Ref to the hidden file input
  const fileInputRef = React.useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const PaginationComponent = () => {
    let active = currentPage;
    let items = [];
    for (let number = 1; number <= Math.ceil(images.length / imagesPerPage); number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => setCurrentPage(number)}>
          {number}
        </Pagination.Item>,
      );
    }
  
    return (
      <Pagination className="justify-content-center">{items}</Pagination>
    );
  };

  return (
    <>
    <Container fluid className="hero-section pt-0">
        <Row>
            <Col className="col-12 col-md-12 text-dark pt-3">
                <Row>
                  <Col className="col px-2 text-center">
                    <PaginationComponent />
                  </Col>
                </Row>
                <Row className="px-2">
                    {paginatedImages.map((image, index) => (
                    <Col key={index} xs={3} sm={3} className="mb-3">
                        <img 
                        src={image.url} 
                        alt={`img-${image.key}`} 
                        className="d-block w-100 shadow-sm" 
                        style={{ aspectRatio: '4/3', borderRadius: '8px' }}
                        onClick={() => openLightbox(image.syncId)}
                        />
                    </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    </Container>
    </>
  );
};

export default GalleryComponent;
