import React, { useState } from 'react';
import { Container, Card, Button, Form, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from "firebase/app";
import app from "../firebase";
import { useFlashUpdate } from "../contexts/FlashContext";

export default function GoogleLogin() {
  const [isRegister, setIsRegister] = useState(false);
  const flash = useFlashUpdate();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const redirectPath = location.search ? `/album${location.search}` : '/';

  async function handleGoogleLogin(event) {
    event.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");

    try {
      await app.auth().signInWithPopup(provider);
      flash({ message: "Login success. Welcome back!", variant: "success" });
      history.push(redirectPath);
    } catch (error) {
      flash({ message: error.message, variant: "danger" });
    }
  }

  async function handleEmailPasswordAuth(event) {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    try {
      if (isRegister) {
        await app.auth().createUserWithEmailAndPassword(email, password);
        flash({ message: "Account created successfully", variant: "success" });
      } else {
        await app.auth().signInWithEmailAndPassword(email, password);
        flash({ message: "Login success. Welcome back!", variant: "success" });
      }
      history.push(redirectPath);
    } catch (error) {
      flash({ message: error.message, variant: "danger" });
    }
  }

  return (
    <Container className="handsbg pt-5">
      <Row className="justify-content-md-center py-5">
        <Col xs={12} md={6}>
          <Card className="my-5 bg-white bg-gradient shadow-sm text-dark">
            <Card.Body>
              <h1 className="text-center text-success mb-5">PartyPics</h1>

              <Button className="w-100 mb-3 shadow-sm btn btn-success" variant="success" onClick={handleGoogleLogin}>
                Log In with Google
              </Button>

              <h3 className="text-center mb-4 border-top border-3 pt-3">{isRegister ? 'Sign Up' : 'Log In'}</h3>
              <Form onSubmit={handleEmailPasswordAuth}>
                <Form.Group className="mb-3 shadow-sm border border-1">
                  <Form.Control type="email"placeholder="Email" name="email" required />
                </Form.Group>

                <Form.Group className="mb-3 shadow-sm border border-1">
                  <Form.Control type="password" placeholder="Password" name="password" required />
                </Form.Group>

                <Button className="w-100 mb-3 shadow-sm btn btn-info" variant="info" type="submit">
                  {isRegister ? 'Sign Up' : 'Log In'}
                </Button>
              </Form>

              <Button
                variant="link"
                className="btn w-100"
                onClick={() => setIsRegister(!isRegister)}
              >
                {isRegister ? 'Already have an account? Log In' : "Don't have an account? Sign Up"}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
