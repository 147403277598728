// Updated React Component Code with Enhanced Room and Key Security
import React, { useRef, useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Form, ListGroup, Container, Navbar } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
// import Highlights from './SyncHighlight';
import Share from './RetroShare';
// import SyncStream from './SyncStream';
import './RetroCam.css'

const CameraComponent = () => {
  const videoRef = useRef(null);
  const flashRef = useRef(null);
  const socket = useRef(null);
  const syncStreamRef = useRef(null); // Reference for SyncStream component
  const [roomCode, setRoomCode] = useState('');
  const [key, setKey] = useState('');
  const [syncId, setSyncId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [activeUsers, setActiveUsers] = useState(0);
  const [photoNotification, setPhotoNotification] = useState('📷');
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const albumUrl = `/album?room=${roomCode}&key=${key}`;
  const roomType = 'retro';

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get('room') || uuidv4().substr(0, 6);
    const key = urlParams.get('key') || uuidv4().substr(0, 6);
    setRoomCode(room);
    setKey(key);
    window.history.replaceState(null, '', `?room=${room}&key=${key}`);
    // Firebase: Save roomCode and key
    // const roomsRef = firebase.database().ref(`rooms/${firebase.auth().currentUser.uid}/${room}`);
    const roomRef = firebase.database().ref(`rooms/${firebase.auth().currentUser.uid}/${room}`);

    roomRef.once('value', snapshot => {
        if (snapshot.exists()) {
        // Room exists, only update dateLastUsed
        roomRef.update({ dateLastUsed: firebase.database.ServerValue.TIMESTAMP });
        } else {
        // Room does not exist, set both dateCreated and dateLastUsed
        roomRef.set({
            key: key,
            dateCreated: firebase.database.ServerValue.TIMESTAMP,
            dateLastUsed: firebase.database.ServerValue.TIMESTAMP
        });
        }
    });
  }, []);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const constraints = { video: { facingMode: 'environment', width: { ideal: 4096 }, height: { ideal: 2160 } } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      } catch (err) {
        console.error("Error accessing the device's camera", err);
      }
    };

    getMedia();

    // Setup WebSocket connection
    socket.current = io(`https://splatsocket.redradar.dev/${roomCode}/${key}`, { withCredentials: true }); // PROD @ AWS
    // socket.current = io(`https://promsocket.redradar.dev/${roomCode}/${key}`, { withCredentials: true }); // DEV @ AWS
    // socket.current = io(`https://splatsocket-dev3.redradar.dev/${roomCode}/${key}`, { withCredentials: true }); // DEV @ RR-SPACEBOOK
    

    socket.current.on('updateUserList', (users) => {
      setConnectedUsers(users);
    });

    socket.current.on('takePhoto', (syncId) => takePhoto(syncId));
    socket.current.on('connect', () => setIsSocketConnected(true));
    socket.current.on('disconnect', () => setIsSocketConnected(false));
    socket.current.on('updateActiveUsers', (count) => setActiveUsers(count));
    socket.current.on('triggerCountdown', (countdown) => {
      setPhotoNotification(countdown.toString());
      setIsCountdownActive(countdown > 0);
      if (countdown === 0) {
        setTimeout(() => {
          setPhotoNotification('📷');
          setIsCountdownActive(false);
        }, 1000);
      }
    });
    
    return () => {
      socket.current.emit('leaveRoom', roomCode);
      socket.current.disconnect();
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [roomCode, key]);

  if (!firebase.apps.length) {
    firebase.initializeApp({ /* Firebase config */ });
  }

  const refreshSyncStream = () => {
    if (syncStreamRef.current) {
      syncStreamRef.current.refresh(); // Call refresh method of SyncStream
    }
  };

  const takePhoto = async (syncId) => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
    const photo = canvas.toDataURL('image/jpeg');

    const storageRef = firebase.storage().ref();
    const photoRef = storageRef.child(`photos/${roomCode}/${syncId}/${firebase.auth().currentUser.uid}/${Date.now()}.jpg`);
    await photoRef.putString(photo, 'data_url');
    const url = await photoRef.getDownloadURL();

    // Trigger screen flash
    flashRef.current.style.display = 'block';
    setTimeout(() => flashRef.current.style.display = 'none', 100);

    firebase.database().ref('photos').push({
      uid: firebase.auth().currentUser.uid,
      url: url,
      syncId: syncId,
      roomCode: roomCode,
      keyCode: key,
      camera: roomType,
      timestamp: firebase.database.ServerValue.TIMESTAMP
    });

    socket.current.emit('photoTaken', { clientId: firebase.auth().currentUser.uid, url: url });

    // After photo is saved, refresh SyncStream component
    setTimeout(refreshSyncStream, 2000);
  };

  const triggerShutterSync = () => {
    const newSyncId = uuidv4();
    setSyncId(newSyncId);
    socket.current.emit('triggerShutterSync', { roomCode, syncId: newSyncId });
  };

  const handleJoinRoom = (e) => {
    e.preventDefault();
    setShowModal(false);
    const formData = new FormData(e.target);
    const room = formData.get('room');
    const key = formData.get('key');
    setRoomCode(room);
    setKey(key);
    window.history.replaceState(null, '', `?room=${room}&key=${key}`);
  };

  const fetchUserDetails = async (userIds) => {
    const usersDetails = await Promise.all(userIds.map(async (userId) => {
      const userRef = firebase.database().ref(`/users/${userId}`);
      const snapshot = await userRef.once('value');
      return { userId, ...snapshot.val() };
    }));
    setConnectedUsers(usersDetails);
  };
  
  useEffect(() => {
    if (connectedUsers.length > 0) {
      fetchUserDetails(connectedUsers);
    }
  }, [connectedUsers]);

  return (
    <div>
      <Container fluid className="retroBody mx-0 px-0 my-0 py-2 bg-dark">
        <Row className="my-0 mx-0 px-3 py-0 justify-content-between">
            <Col className="col-2 py-0 my-0 bg-dark text-light text-center">
                <Row className="my-2 mx-0 ml-0 pl-0">
                    <h5><span className="text-light">In Party:</span></h5> <h3>{activeUsers.toString().padStart(4, '0')}</h3>
                </Row>
                <Row className="my-2 mx-0 text-center">
                    <QRCode className="text-center px-0"
                        value={`${window.location.origin}${window.location.pathname}?room=${roomCode}&key=${key}`}
                        size={135}
                        bgColor={"#f6f6f6"}
                        fgColor={"#0b0b0b"}
                        level={"H"}
                        includeMargin={true}
                        imageSettings={{
                            src: "https://partypics.zip/logo512.png",
                            x: undefined,
                            y: undefined,
                            height: 50,
                            width: 50,
                            excavate: true,
                        }}
                        />
                </Row>
                <Row className="my-1 mx-0 ml-0 pl-0 text-center">
                    <Col className="col-12">
                        <h5>Room: <span className="text-warning">{roomCode}</span></h5>
                    </Col>
                    <Col className="col-12">
                        <h5>Key: <span className="text-warning">{key}</span></h5>
                    </Col>
                </Row>
                <Row className="my-0 mx-0 text-center">
                        <a href="/"><button className="btn btn-danger btn-sm">EXIT</button></a>
                </Row>
            </Col>
            <Col className="col-8 px-1 py-2 my-2 bg-warning text-light text-center" style={{ borderRadius: '25px' }}>
                <video ref={videoRef} style={{ height: '80vh', objectFit: 'cover', aspectRatio: '4 / 3', borderRadius: '15px' }} autoPlay muted playsInline className="vintage"></video>
            </Col>
            <Col className="col-2 bg-dark text-light">
                <Row className="my-0 mx-0 text-center">
                        <Share roomCode={roomCode} keyCode={key} /> {/* Pass "key" as "keyCode" */}
                </Row>
                <Row className="my-0 mx-0 d-flex align-items-center text-center text-warning">
                    <Col className="col-6 text-center px-2 py-1 my-1 rounded-circle">
                        <div className="rounded-circle vintage" style={{ width: '25px', height: '25px', backgroundColor: isSocketConnected ? 'green' : 'red', margin: 'auto' }}></div>
                    </Col>
                    <Col className="col-6 px-0 text-center">
                        <h1>{photoNotification}</h1>
                    </Col>
                </Row>
                <Row className="my-3 mx-1 py-2">
                    {!isCountdownActive ? (
                        <Button className="btn btn-outline-info rounded-circle" onClick={triggerShutterSync} style={{ width: '100%', height: '117px', fontSize: '1.15em', borderRadius: '75px' }}>Shoot!</Button>
                    ) : (
                        <Button className="btn btn-danger rounded-circle" disabled style={{ width: '100%', height: '117px', fontSize: '1.5em', borderRadius: '75px' }}>Wait!</Button>
                    )}
                </Row>
                <Row className="my-0 mx-0 text-center py-2">
                    {/* <Highlights /> */}
                    <Button href={albumUrl} variant="info" className="btn btn-info shadow-sm text-center pr-3" style={{ width: '100%', fontSize: '1.5em', marginTop: '0.5em'}}><strong>Album</strong></Button>
                </Row>
            </Col>
        </Row>

        {/* Screen Flash */}
        <div ref={flashRef} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', backgroundColor: 'white', display: 'none', zIndex: 9999 }}></div>

      </Container>

      {/* Join Room Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Join a Room</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleJoinRoom}>
          <Modal.Body>
            <Form.Group controlId="formRoomCode">
              <Form.Label>Room Code</Form.Label>
              <Form.Control type="text" placeholder="Enter room code" name="room" maxlength="6" minlength="6" pattern="[a-zA-Z0-9 ]+" required />
            </Form.Group>
            <Form.Group controlId="formKey">
              <Form.Label>Key</Form.Label>
              <Form.Control type="text" placeholder="Enter key" name="key" maxlength="6" minlength="6" pattern="[a-zA-Z0-9 ]+" required />
            </Form.Group>
            <span className="text-warning text-right">LETTERS & NUMBERS ONLY</span><br />
            <span className="text-warning text-end">CODES & KEYS HAVE 6-CHARACTERS</span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" type="submit">Join Room</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default CameraComponent;
