import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Pagination, Modal, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lightbox, setLightbox] = useState({ open: false, image: null, imageKey: null, roomCode: '', keyCode: '' });
  const history = useHistory();
  const location = useLocation();
  const imagesPerPage = 60;

  const searchParams = new URLSearchParams(location.search);
  const roomFilter = searchParams.get('room');
  const keyFilter = searchParams.get('key');

  useEffect(() => {
    const fetchImages = async () => {
      let query = firebase.database().ref('photos').orderByChild('timestamp');
      if (roomFilter && keyFilter) {
        query = query.orderByChild('roomCode_keyCode').equalTo(`${roomFilter}_${keyFilter}`);
      }

      const snapshot = await query.once('value');
      const data = snapshot.val();
      if (data) {
        const loadedImages = Object.entries(data).reverse().map(([key, value]) => ({ key, ...value }));
        setImages(loadedImages);
      }
    };

    fetchImages();
  }, [roomFilter, keyFilter]);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(images.length / imagesPerPage); i++) {
    pageNumbers.push(i);
  }

  const openLightbox = (image, key, roomCode, keyCode) => setLightbox({ open: true, image, imageKey: key, roomCode, keyCode });

  const closeLightbox = () => setLightbox({ open: false, image: null, imageKey: null, roomCode: '', keyCode: '' });

  const deleteImage = async () => {
    await firebase.database().ref(`photos/${lightbox.imageKey}`).remove();
    setImages(images.filter(image => image.key !== lightbox.imageKey));
    closeLightbox();
  };

  const handleKeyUp = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        closeLightbox();
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  const isFilterActive = useMemo(() => roomFilter || keyFilter, [roomFilter, keyFilter]);

  const removeFilter = () => {
    history.push(location.pathname);
  };

  const applyFilter = () => {
    if (lightbox.roomCode && lightbox.keyCode) {
      history.push(`/iddqd?room=${lightbox.roomCode}&key=${lightbox.keyCode}`);
      closeLightbox();
    }
  };

  return (
    <Container className="mt-2">
      <Row>
        {currentImages.map(({ key, url, roomCode, keyCode }) => (
          <Col key={key} xs={2} md={2} className="mb-3">
            <img src={url} alt={`img-${key}`} className="img-fluid" style={{ cursor: 'pointer', aspectRatio: '4/3' }} onClick={() => openLightbox(url, key, roomCode, keyCode)} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <Pagination>
            {pageNumbers.map(number => (
              <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                {number}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
      {isFilterActive && (
        <Row className="mt-2">
          <Col>
            <Button variant="primary" onClick={removeFilter}>Remove Filter</Button>
          </Col>
        </Row>
      )}
      <Modal
        show={lightbox.open}
        onHide={closeLightbox}
        size="lg"
        centered
        dialogClassName="modal-85w"
        aria-labelledby="lightbox-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="lightbox-modal">Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={lightbox.image} alt="lightbox" className="img-fluid" style={{ maxHeight: '100%', maxWidth: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeLightbox}>Close</Button>
          <Button variant="info" onClick={applyFilter}>Apply Filter</Button>
          <Button variant="danger" onClick={deleteImage}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default GalleryComponent;
