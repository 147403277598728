import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

const RecentGalleryComponent = () => {
  const [recentImage, setRecentImage] = useState(null);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    const fetchRecentImage = async () => {
      const userId = firebase.auth().currentUser.uid;
      const snapshot = await firebase.database().ref('photos').orderByChild('uid').equalTo(userId).limitToLast(1).once('value');
      const data = snapshot.val();
      if (data) {
        const recentImageData = Object.entries(data)[0][1];
        setRecentImage({ key: recentImageData.syncId, ...recentImageData });
      }
    };

    fetchRecentImage();
  }, []);

  const openLightbox = (syncId) => {
    setLightboxImages([recentImage]);
    setSelectedImage(recentImage?.url);
    setShowLightbox(true);
  };

  const selectImage = (url) => {
    setSelectedImage(url);
  };

  return (
    <Container>
      <Row>
        {recentImage && (
          <Col md={12} className="mb-12">
            <img
              src={recentImage.url}
              alt={`img-${recentImage.syncId}`}
              className="img-fluid"
              onClick={() => openLightbox(recentImage.syncId)}
              style={{ cursor: 'pointer' }}
            />
          </Col>
        )}
      </Row>
      <ResponsiveModal open={showLightbox} onClose={() => setShowLightbox(false)} center closeOnEsc closeOnOverlayClick>
        <img src={selectedImage} alt="Selected" style={{ maxHeight: '80vh', maxWidth: '100%' }} />
        <div style={{ display: 'flex', overflowX: 'auto', marginTop: '10px' }}>
          {lightboxImages.map(({ key, url }) => (
            <img 
              key={key} 
              src={url} 
              alt={`img-${key}`} 
              style={{ maxHeight: '100px', maxWidth: '100px', margin: '0 5px', cursor: 'pointer' }} 
              onClick={() => selectImage(url)} 
            />
          ))}
        </div>
      </ResponsiveModal>
    </Container>
  );
};

export default RecentGalleryComponent;
