import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, Toast } from 'react-bootstrap';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';

export const DownloadModal = ({ images, show, onHide, roomCode, keyCode }) => {
    const [selectedImages, setSelectedImages] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');

    const toggleImageSelection = (key) => {
        setSelectedImages(prev => ({
        ...prev,
        [key]: !prev[key]
        }));
    };

    const downloadImages = async () => {
        const selectedImageKeys = Object.entries(selectedImages)
          .filter(([, isSelected]) => isSelected)
          .map(([key]) => key);
    
        if (selectedImageKeys.length === 1) {
          // Directly download the single selected image
          const singleImage = images.find(image => image.key === selectedImageKeys[0]);
          saveAs(singleImage.url, `${roomCode}_img001.jpg`);
        } else {
          // Create a zip file for multiple images
          const zip = new JSZip();
          const folderName = `PartyPics_${roomCode}`;
          const folder = zip.folder(folderName);
    
          for (let i = 0; i < selectedImageKeys.length; i++) {
            const imageKey = selectedImageKeys[i];
            const image = images.find(img => img.key === imageKey);
            const response = await fetch(image.url);
            const blob = await response.blob();
            folder.file(`${folderName}_img${(i + 1).toString().padStart(3, '0')}.jpg`, blob, { binary: true });
          }
    
          const zipBlob = await zip.generateAsync({ type: "blob" });
          saveAs(zipBlob, `${folderName}.zip`);
        }
      };

    const selectedCount = Object.values(selectedImages).filter(Boolean).length;

  return (
    <>
    <Modal show={show} onHide={onHide} size="lg">
        <Modal.Body>
          <Row>
            {images.map((image, index) => (
              <Col xs={6} sm={4} md={3} lg={2} key={index} className="mb-3">
                <img src={image.url} alt={`img-${image.key}`} className="img-fluid" />
                <Form.Check
                  type="checkbox"
                  checked={!!selectedImages[image.key]}
                  onChange={() => toggleImageSelection(image.key)}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={downloadImages} disabled={selectedCount === 0}>
            Download {selectedCount} Image{selectedCount !== 1 ? 's' : ''}
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
        <Toast.Header>
          <strong className="mr-auto">Download Ready</strong>
        </Toast.Header>
        <Toast.Body>
          Click the download link on your selected image(s).
        </Toast.Body>
      </Toast>
    </>
  );
};

// In your GalleryComponent, use the DownloadModal component
// OLD <DownloadModal images={images} />
// <Button variant="dark" className="btn btn-dark" onClick={() => setShowDownloadModal(true)}>Download Images</Button>
// <DownloadModal images={images} show={showDownloadModal} onHide={() => setShowDownloadModal(false)} />