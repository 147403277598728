// Terms of Service Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfService = () => (
    <Container className="my-5">
        <Row>
            <Col md={12}>
                <h2 className="text-center text-info">Terms of Service</h2>
                <p className="text-dark">
                    Welcome to PartyPics! By accessing or using our platform, you agree to be bound by these terms. Please read them carefully.
                </p>
                <h4 className="text-dark">1. Use of Our Service</h4>
                <p className="text-dark">
                    You must follow any policies made available to you within the Service. You may use our Service only as permitted by law, including applicable export and re-export control laws and regulations.
                </p>
                <h4 className="text-dark">2. Privacy Protection</h4>
                <p className="text-dark">
                    Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service. By using our Service, you agree that PartyPics can use such data in accordance with our privacy policies.
                </p>
                <h4 className="text-dark">3. Your Content in Our Services</h4>
                <p className="text-dark">
                    Our Service allows you to upload, submit, store, send, or receive content. You retain ownership of any intellectual property rights that you hold in that content.
                </p>
                <h4 className="text-dark">4. About Software in Our Services</h4>
                <p className="text-dark">
                    When a Service requires or includes downloadable software, this software may update automatically on your device once a new version or feature is available.
                </p>
                <h4 className="text-dark">5. Modifying and Terminating Our Services</h4>
                <p className="text-dark">
                    We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.
                </p>
            </Col>
        </Row>
    </Container>
);

export default TermsOfService;