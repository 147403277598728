
import React, { useState } from 'react';
import {Navbar, Nav, Image} from 'react-bootstrap'

import { useAuth } from "../contexts/FirebaseAuth"

import "./TopNav.css"


export default function TopNav() {
    const { currentUser } = useAuth()
    //const [expanded, setExpanded] = useState(false)
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
      };

    return (
        <Navbar 
            bg="success" // controls nav background color
            className="bg-success bg-gradient shadow-sm" // custom classes
            // className={darkMode ? 'dark-mode' : ''}
            fixed="top" // keeps nav at top (not necessary when sticky top is set)
            sticky="top" // pushes page below, prevents overlap when expanded
            expand="sm" // collapse when less wide than this breakpoint
            collapseOnSelect // collapse after a nav link has been clicked
            style={{marginBottom:"unset"}}
            data-bs-theme="dark"
            data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation"

            // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
            //expanded={expanded}
            //onToggle={next => setExpanded(next)}
        >
            <Navbar.Brand href="/" style={{fontSize:22}} className="text-light text-bold px-3">
                <img src="./assets/navlogo.png" alt="" width="30" height="24" className="d-inline-block align-text-top mr-2"></img>
                PartyPics
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav px-3" />
            <Navbar.Collapse id="basic-navbar-nav">
                
                <Nav className="ml-auto ${darkMode ? 'dark-mode' : ''}`">
                    
                    <Nav.Link href="/splat" className="">Room</Nav.Link>
                    <Nav.Link href="/album" className="">Album</Nav.Link>
                    <Nav.Link href="/potato" className="">Potato</Nav.Link>
                    <Nav.Link href="/retro" className="">Retro</Nav.Link>
                    <Nav.Link href="/jargon" className="">About</Nav.Link>
                    {/* <Nav.Link href="/products">Products</Nav.Link>
                    {/* <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/camera">Mash Camera</Nav.Link>
                    <Nav.Link className="text-dark px-3" href="/images">Images</Nav.Link>
                    <Nav.Link className="text-dark px-3" href="/gallery">Gallery</Nav.Link> */}
                    {/* <button className="button button-primary" onClick={toggleDarkMode}>
                        {darkMode ? 'Light Mode' : 'Dark Mode'}
                    </button> */}
                    {!currentUser &&
                        <Nav.Link href="/login" className="px-3">Login</Nav.Link>
                    }
                </Nav>

                { currentUser &&
                    <Nav.Link href="/profile" className="profile-link px-2 text-right">
                        <Image roundedCircle src={currentUser.photoURL} alt="user profile" height="35px"/>
                    </Nav.Link>
                }

            </Navbar.Collapse>
        </Navbar>
    )
}
