

import React, { useState, useEffect, PureComponent } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Card, Button, Image, Tab, Nav, Row, Col, Table } from 'react-bootstrap'

import app, {fetchOrders} from "../firebase"
import { useAuth } from "../contexts/FirebaseAuth"
import { useFlashUpdate } from "../contexts/FlashContext"
import Images from './Images'
import Albums from './SyncAlbums'
import History from './History'

function AccountTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    async function handleLogout() {
        await app.auth().signOut()
        console.log("LOGOUT SUCCESS")
        flash({message:"Logout success. Have a nice day!", variant: "success"})
        history.push("/login")
    }

    return (
        <>
            <p className="lead text-dark">You are logged in as...</p>

            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{ maxWidth: "400px"}}>
                    <Card className="card bg-info ">
                        <Card.Body>
                            <Image roundedCircle
                                    src={user.photoURL}
                                    alt="user profile"
                                    style={{
                                        marginBottom:15,
                                        display:"block",
                                        marginLeft:"auto",
                                        marginRight:"auto",

                                    }}
                                    //height="65px"
                                />

                            <div>Name: <pre>{user.displayName}</pre></div>
                            {/* <div>Email: <pre>{user.email}</pre></div>
                            <div>User Id: <pre>{user.uid}</pre></div> */}
                            <Button variant="danger" className="btn btn-danger shadow-sm mt-2" onClick={handleLogout}>
                                Log Out
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    )
}

class OrdersTab extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {orders: []}
    }

    render(){
        var rows = this.state.orders.map((order) => {
            var orderDate = new Date(parseInt(order.orderAt))
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
            return (
                <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.productName}</td>
                    <td>{order.productPrice}</td>
                    <td>{orderDate.toLocaleString()}</td>
                </tr>
            )
        })

        return (
            <>
                <h2>Order History</h2>
                <p className="lead">Here are your recent orders...</p>

                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Order Id</th>
                            <th>Product Name</th>
                            <th>Product Price</th>
                            <th>Order Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>

            </>
        )
    }

    async componentDidMount(){
        console.log("ORDERS TAB DID MOUNT")
        var orders = await fetchOrders(this.props.user)
        //console.log("MOUNTED WITH", products.length, "PRODUCTS")
        var sortedOrders = orders.sort((a,b) => parseInt(b.orderAt) - parseInt(a.orderAt)) // DESC
        this.setState({orders: sortedOrders})
    }
}

function ImagesTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (

        <Images />
    )
}

function AlbumsTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (
        <Albums />
    )
}

function HistoryTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (
        <History />
    )
}

export default function Profile() {
    const { currentUser } = useAuth()

    //console.log("WINDOW LOCATION", window.location)
    var activeTabKey = "history"
    if(window.location.hash === "#orders"){
        activeTabKey = "orders"
    }

    return (
        <>
        <Container fluid className="hero-section pt-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey={activeTabKey} transition={false}>
        <Row className="">
            <Col className="col-12 col-md-2 text-light text-center bg-warning shadow-lg handsbgcontain">
                <Row>
                    <Col>
                        <h1 className="my-5 text-light fw-bold"><strong>User Profile</strong></h1>
                    </Col>
                </Row>
                <Row className="my-5 mt-5">
                    <Col className="col-12 mx-0 px-1">
                        <AccountTab user={currentUser}/>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col className="col-12 mx-0 px-3">
                        <Button href="/splat" variant="success" className="btn btn-success my-2 shadow-sm px-3 w-100"><strong>Start a new Room</strong></Button>
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button variant="danger" className="btn btn-dangermy-2 shadow-sm px-3 w-100" href="/potato"><strong>Play Hot Potato</strong></Button>
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button variant="light" className="btn btn-light my-2 shadow-sm border border-2 px-3 w-100" href="/retro"><strong>Use Retro Camera</strong></Button>
                    </Col>
                    {/* <Col className="col-12 mx-0 px-1">
                        <Button onClick={handleShowModal} variant="info" className="btn btn-info mx-2 my-2 shadow-sm"><strong>Join another Room</strong></Button>
                    </Col> */}
                </Row>
            </Col>
            <Col className="col-12 col-md-10 text-dark text-center pt-3">
                <Row className="my-3">
                    <Nav variant="tabs" className="" style={{marginBottom:"1em"}}>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="account">Account</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="orders">Orders</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="albums">Albums</Nav.Link>
                        </Nav.Item> */}

                        <Nav.Item>
                            <Nav.Link eventKey="history">My Rooms</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="images">My Images</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Row>
                <Row className="px-3">
                    <Tab.Content>
                        {/* <Tab.Pane eventKey="account">
                            <AccountTab user={currentUser}/>
                        </Tab.Pane>

                        <Tab.Pane eventKey="orders">
                            <OrdersTab user={currentUser}/>
                        </Tab.Pane>

                        <Tab.Pane eventKey="albums">
                            <AlbumsTab user={currentUser}/>
                        </Tab.Pane> */}
                        
                        <Tab.Pane eventKey="images">
                            <ImagesTab user={currentUser}/>
                        </Tab.Pane>
                
                        <Tab.Pane eventKey="history">
                            <HistoryTab user={currentUser}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Row>
            </Col>
        </Row>
        </Tab.Container>
        </Container>

        {/* <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Join a Room</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleJoinRoom}>
                    <Modal.Body>
                        <Form.Group controlId="formRoomCode">
                            <Form.Label>Room Code</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter room code" 
                                value={roomCode}
                                onChange={(e) => setRoomCode(e.target.value)} 
                                required 
                            />
                        </Form.Group>
                        <Form.Group controlId="formKey">
                            <Form.Label>Key</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter key" 
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                                required 
                            />
                        </Form.Group>
                        <span className="text-warning text-right">LETTERS & NUMBERS ONLY</span><br />
                        <span className="text-warning text-end">CODES & KEYS HAVE 6-CHARACTERS</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                        <Button variant="primary" type="submit">Join Room</Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}
        </>
    )
}
