// IddqdComponent.js
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const IddqdComponent = () => {
  const [images, setImages] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const room = searchParams.get('room');
    const key = searchParams.get('key');

    if (!room || !key) {
      history.push('/idkfa');
      return;
    }

    const fetchImages = async () => {
      const query = firebase.database().ref('photos')
        .orderByChild('roomCode_keyCode')
        .equalTo(`${room}_${key}`);
      const snapshot = await query.once('value');
      const data = snapshot.val();

      if (data) {
        const loadedImages = Object.entries(data).map(([key, value]) => ({ key, ...value }));
        setImages(loadedImages);
      }
    };

    fetchImages();
  }, [location, history]);

  return (
    <Container className="mt-4">
      <h1>Images for Room: {location.search}</h1>
      {images.length === 0 ? (
        <p>No images found for the specified room and key.</p>
      ) : (
        <div className="image-gallery">
          {images.map(image => (
            <div key={image.key} className="image-item">
              <img src={image.url} alt={`Image ${image.key}`} />
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

export default IddqdComponent;
