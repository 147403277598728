// Updated ProtectedRoute.js with query parameter preservation
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/FirebaseAuth";

export default function ProtectedRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={{
            pathname: "/login",
            search: location.search // preserve query parameters during redirect
          }} />
        );
      }}
    >
    </Route>
  );
}