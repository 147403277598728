// Enhanced GalleryComponent with RoomCode and Key Input Fields for Dynamic Image Loading

import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Container, Row, Col, Carousel, Form, Button, Pagination, Dropdown, DropdownButton } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
import { DownloadModal } from './ImageDownload';
import Slider from 'react-input-slider';

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [roomCode, setRoomCode] = useState('');
  const [key, setKey] = useState('');
  const [filterUid, setFilterUid] = useState('all');
  const [filterSyncId, setFilterSyncId] = useState('all');
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const albumUrl = `/album?room=${roomCode}&key=${key}`;
  const backUrl = `/splat?room=${roomCode}&key=${key}`;
  const retroUrl = `/retro?room=${roomCode}&key=${key}`;
  const potatoUrl = `/potato?room=${roomCode}&key=${key}`;
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 16;
  const [userUids, setUserUids] = useState(new Set());
  const [syncIds, setSyncIds] = useState(new Set());
  const [userDetails, setUserDetails] = useState({});
  const [syncDetails, setSyncDetails] = useState({});
  const [roomName, setRoomName] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [roomCreator, setRoomCreator] = useState(''); 
  

  // Pagination and filter logic
  const paginatedFilteredImages = useMemo(() => {
    const startIndex = (currentPage - 1) * imagesPerPage;
    let filteredImages = images;
    if (filterUid !== 'all') {
      filteredImages = filteredImages.filter(image => image.uid === filterUid);
    }
    if (filterSyncId !== 'all') {
      filteredImages = filteredImages.filter(image => image.syncId === filterSyncId);
    }
    return filteredImages.slice(startIndex, startIndex + imagesPerPage);
  }, [currentPage, images, filterUid, filterSyncId]);

  const fetchUserDetails = async (uids) => {
    const details = {};
    for (const uid of uids) {
      const userRef = firebase.database().ref(`users/${uid}`);
      const snapshot = await userRef.once('value');
      const userData = snapshot.val();
      details[uid] = userData ? `${userData.displayName || userData.email}` : `User (${uid})`;
    }
    setUserDetails(details);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const pad = (num) => num.toString().padStart(2, '0');
    const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${pad(date.getMonth() + 1)}/${pad(date.getDate())}/${date.getFullYear()} ${pad(hours)}:${pad(date.getMinutes())}:${pad(date.getSeconds())} ${ampm}`;
  };
  
  const fetchImages = async (room, keyCode) => {
    const snapshot = await firebase.database().ref('photos')
      .orderByChild('roomCode')
      .equalTo(room)
      .once('value');

    const allData = snapshot.val();
    if (allData) {
      const uids = new Set();
      const syncs = new Set();
      const filteredImages = Object.entries(allData)
        .filter(([_, value]) => value.keyCode === keyCode)
        .map(([key, value]) => {
          uids.add(value.uid);
          syncs.add(value.syncId);
          return { key, ...value };
        });
      // Processing for syncIds with timestamps
      const syncsWithTimestamp = {};
      Object.entries(allData).forEach(([_, value]) => {
        if (value.syncId && value.timestamp) {
          if (!syncsWithTimestamp[value.syncId] || syncsWithTimestamp[value.syncId] < value.timestamp) {
            syncsWithTimestamp[value.syncId] = value.timestamp;
          }
        }
      });
      const syncDetailsFormatted = Object.keys(syncsWithTimestamp).reduce((acc, syncId) => {
        acc[syncId] = formatTimestamp(syncsWithTimestamp[syncId]);
        return acc;
      }, {});
      setSyncDetails(syncDetailsFormatted);

      setImages(filteredImages);
      setUserUids(uids);
      setSyncIds(syncs);
      // Fetch user details
      fetchUserDetails(uids);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get('room') || '';
    const keyCode = urlParams.get('key') || '';
    setRoomCode(room);
    setKey(keyCode);

    if (room && keyCode) {
      fetchImages(room, keyCode);
      fetchRoomDetails(room);
      fetchRoomCreator(room);
    }
  }, []);

  const fetchRoomCreator = async (room) => {
    const photosRef = firebase.database().ref('photos').orderByChild('roomCode').equalTo(room);
    const snapshot = await photosRef.limitToFirst(1).once('value');
    if (snapshot.exists()) {
      const firstPhoto = Object.values(snapshot.val())[0];
      const userRef = firebase.database().ref(`users/${firstPhoto.uid}`);
      const userSnapshot = await userRef.once('value');
      const userData = userSnapshot.val();
      setRoomCreator(userData ? `${userData.displayName || userData.email}` : `${firstPhoto.uid}`);
    }
  };

  const fetchRoomDetails = async (room) => {
    const uid = firebase.auth().currentUser.uid;
    const roomRef = firebase.database().ref(`rooms/${uid}/${room}`);
    const snapshot = await roomRef.once('value');
    if (snapshot.exists()) {
      const roomData = snapshot.val();
      setRoomName(roomData.name || room);
      setCreatedDate(new Date(roomData.dateCreated).toLocaleString());
    }
  };

  const handleRoomChange = (e) => {
    setRoomCode(e.target.value);
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchImages(roomCode, key);
  };

  const openLightbox = (syncId) => {
    const relatedImages = images.filter(image => image.syncId === syncId);
    setLightboxImages(relatedImages);
    setSelectedImage(relatedImages[0]?.url);
    setShowLightbox(true);
  };

  const selectImage = (url) => {
    setSelectedImage(url);
  };

  const handleJoinRoom = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    history.push(`/splat?room=${roomCode}&key=${key}`);
  };

  const handleShowModal = () => setShowModal(true);

  const uploadImages = async (files) => {
    const syncId = uuidv4();
    const promises = [];
    for (const file of files) {
      const photoRef = firebase.storage().ref(`photos/${roomCode}/${syncId}/${firebase.auth().currentUser.uid}/${file.name}`);
      const promise = photoRef.put(file)
        .then(async () => {
          const url = await photoRef.getDownloadURL();
          return firebase.database().ref('photos').push({
            uid: firebase.auth().currentUser.uid,
            url: url,
            syncId: syncId,
            roomCode: roomCode,
            keyCode: key,
            timestamp: firebase.database.ServerValue.TIMESTAMP
          });
        });
      promises.push(promise);
    }

    await Promise.all(promises);
    // Refresh images after upload
    fetchImages(roomCode, key);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    uploadImages(files);
  };

  // Ref to the hidden file input
  const fileInputRef = React.useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFilterChange = (type, value) => {
    if (type === 'uid') {
      setFilterUid(value);
    } else if (type === 'syncId') {
      setFilterSyncId(value);
    }
  };

  const resetFilters = () => {
    setFilterUid('all');
    setFilterSyncId('all');
    setCurrentPage(1);
  };

  const PaginationComponent = () => {
    let active = currentPage;
    let items = [];
    let numberOfPages = Math.ceil(paginatedFilteredImages.length / imagesPerPage);
    for (let number = 1; number <= numberOfPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => setCurrentPage(number)}>
          {number}
        </Pagination.Item>,
      );
    }

    return (
      <Pagination className="justify-content-center">{items}</Pagination>
    );
  };

  const sortedSyncIds = useMemo(() => {
    return Object.entries(syncDetails).sort((a, b) => a[1].localeCompare(b[1])).map(entry => entry[0]);
  }, [syncDetails]);

  const getCameraClass = (cameraType) => {
    switch (cameraType) {
      case 'splat':
        return 'splat-class';
      case 'retro':
        return 'vintage';
      case 'potato':
        return 'potato-class';
      default:
        return '';
    }
  };

  return (
    <>
    <Container fluid className="hero-section pt-0">
        {/* <Row className="mb-0 mt-0 px-2 text-center text-light bg-dark">
            <Col className="col-12 col-sm-3 mx-0 px-2 py-4 text-center">
                <h5>Different Code? <span className="text-warning">Not the droids you're looking for?</span></h5>
            </Col>
            <Col className="col-6 col-sm-3 mx-0 px-2 py-2 text-center">
                <h5>Room: <Form.Control type="text" placeholder="Enter room code" value={roomCode} onChange={handleRoomChange} required /></h5>
            </Col>
            <Col className="col-6 col-sm-3 mx-0 px-2 py-2 text-center">
                <h5>Key: <Form.Control type="text" placeholder="Enter key" value={key} onChange={handleKeyChange} required /></h5>
            </Col>
            <Col className="col-12 col-sm-3 mx-0 px-2 py-2 text-center">
                <Button type="submit" variant="primary" style={{ width: '100%', marginTop: '0.75rem'}}>Load New Images</Button>
            </Col>
        </Row> */}
        <Row>
            <Col className="col-12 col-md-2 text-dark text-center bg-warning shadow-lg handsbgcontain">
                <Row>
                    <Col>
                        <h1 className="mt-4 py-4 text-light fw-bold"><strong>PartyPics Album</strong></h1>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col className="col-12 mx-0 px-1">
                        <h4>Room: <span className="text-light">{roomCode}</span></h4>
                    </Col>
                    <Col className="col-12 mx-0 px-1">
                        <h4>Key: <span className="text-light">{key}</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 mx-0 px-3">
                        <Button variant="dark" className="btn btn-dark my-2 shadow-sm px-3 w-100" href={backUrl}><strong>Go to this Room</strong></Button>
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button variant="danger" className="btn btn-danger my-2 shadow-sm px-3 w-100" href={potatoUrl}><strong>Play Hot Potato</strong></Button>
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button variant="light" className="btn btn-light my-2 shadow-sm border border-2 px-3 w-100" href={retroUrl}><strong>Use Retro Camera</strong></Button>
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button href="/splat" variant="success" className="btn btn-success my-2 shadow-sm px-3 w-100"><strong>Start a new Room</strong></Button>
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button onClick={handleShowModal} variant="info" className="btn btn-info my-2 shadow-sm px-3 w-100"><strong>Join another Room</strong></Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 mx-0 px-3">
                        <Button onClick={handleFileButtonClick} variant="dark" className="btn btn-dark my-2 shadow-sm px-3 w-100">
                            <strong>Upload Your Images</strong>
                        </Button>
                        <Form.Control 
                        type="file" 
                        multiple 
                        onChange={handleFileChange} 
                        ref={fileInputRef} 
                        style={{ display: 'none' }} 
                        />
                    </Col>
                    <Col className="col-12 mx-0 px-3">
                        <Button variant="dark" className="btn btn-dark my-2 px-3 w-100" onClick={() => setShowDownloadModal(true)}>Download Images</Button>
                        <DownloadModal images={images} show={showDownloadModal} onHide={() => setShowDownloadModal(false)} roomCode={roomCode} keyCode={key}/>
                    </Col>
                </Row>
                
                <Row>
                    <Col className="col-12 mx-0 px-1 py-5">
                        <QRCode 
                            value={`${window.location.origin}${window.location.pathname}?album=${roomCode}&key=${key}`}
                            size={120}
                            bgColor={"#f6f6f6"}
                            fgColor={"#0b0b0b"}
                            level={"H"}
                            includeMargin={true}
                            imageSettings={{
                                src: "https://partypics.zip/logo512.png",
                                x: undefined,
                                y: undefined,
                                height: 40,
                                width: 40,
                                excavate: true,
                            }}
                        />
                    </Col>
                </Row>

                <ResponsiveModal open={showLightbox} onClose={() => setShowLightbox(false)} center closeOnEsc closeOnOverlayClick styles={{ modal: { zIndex: 1050 } }}>
                    <img src={selectedImage} alt="Selected" style={{ maxHeight: '80vh', maxWidth: '100%' }} />
                    <div style={{ display: 'flex', overflowX: 'auto', marginTop: '10px' }}>
                    {lightboxImages.map(({ key, url }) => (
                        <img 
                        key={key} 
                        src={url} 
                        alt={`img-${key}`} 
                        style={{ maxHeight: '100px', maxWidth: '100px', margin: '0 5px', cursor: 'pointer' }} 
                        onClick={() => selectImage(url)} 
                        />
                    ))}
                    </div>
                </ResponsiveModal>
            </Col>
            <Col className="col-12 col-md-10 text-dark pt-3">
                {/* Gallery Filters */}
                <Row className="mb-3">
                    <Col className="col-9 col-md-4">
                        <Row className="mb-3">
                            <Col className="col-12">
                                <h5>Name: <span className="text-info">{roomName}</span></h5>
                            </Col>
                            <Col className="col-12">
                                <h6>Created: <span className="text-info">{createdDate}</span></h6>
                            </Col>
                            {/* <Col className="col-12">
                                <h5>Started By: <span className="text-info">{roomCreator}</span></h5>
                            </Col> */}
                        </Row>
                    </Col>
                    
                    <Col className="col-3 col-md-2">
                        <PaginationComponent />
                    </Col>
                    <Col className="col-12 col-md-2">
                        {(filterUid !== 'all' || filterSyncId !== 'all') && (
                        <Button variant="outline-secondary" onClick={resetFilters}>Reset</Button>
                        )}
                    </Col>
                    <Col className="col-6 col-md-2">
                        <DropdownButton id="dropdown-basic-button" title="Filter by User" className="px-2 w-100">
                        <Dropdown.Item onClick={() => handleFilterChange('uid', 'all')}>All</Dropdown.Item>
                        {[...userUids].map(uid => (
                            <Dropdown.Item key={uid} onClick={() => handleFilterChange('uid', uid)}>{userDetails[uid] || uid}</Dropdown.Item>
                        ))}
                        </DropdownButton>
                    </Col>
                    <Col className="col-6 col-md-2">
                        <DropdownButton id="dropdown-basic-button" title="Filter by Time">
                        <Dropdown.Item onClick={() => handleFilterChange('syncId', 'all')}>All</Dropdown.Item>
                        {sortedSyncIds.map(syncId => (
                            <Dropdown.Item key={syncId} onClick={() => handleFilterChange('syncId', syncId)}>
                            {syncDetails[syncId] || syncId}
                            </Dropdown.Item>
                        ))}
                        </DropdownButton>
                    </Col>
                    
                </Row>
                <Row className="px-2">
                  {paginatedFilteredImages.map((image, index) => (
                    <Col key={index} xs={3} sm={3} className="mb-3">
                      <img 
                        src={image.url} 
                        alt={`img-${image.key}`} 
                        className={`d-block w-100 shadow-sm ${getCameraClass(image.camera)}`} 
                        style={{ aspectRatio: '4/3', borderRadius: '8px' }}
                        onClick={() => openLightbox(image.syncId)}
                      />
                    </Col>
                  ))}
                </Row>
            </Col>
        </Row>
        <Row className="mb-0 mt-0 px-2 text-center text-light bg-dark">
          <Col className="col-12 col-sm-3 mx-0 px-2 py-4 text-center">
              <h5>Different Code? <span className="text-warning">Not the droids you're looking for?</span></h5>
          </Col>
          <Col className="col-6 col-sm-3 mx-0 px-2 py-2 text-center">
              <h5>Room: <Form.Control type="text" placeholder="Enter room code" value={roomCode} onChange={handleRoomChange} required /></h5>
          </Col>
          <Col className="col-6 col-sm-3 mx-0 px-2 py-2 text-center">
              <h5>Key: <Form.Control type="text" placeholder="Enter key" value={key} onChange={handleKeyChange} required /></h5>
          </Col>
          <Col className="col-12 col-sm-3 mx-0 px-2 py-2 text-center">
              <Button type="submit" variant="primary" style={{ width: '100%', marginTop: '0.75rem'}}>Load New Images</Button>
          </Col>
        </Row>
    </Container>
    
    <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Join a Room</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleJoinRoom}>
                <Modal.Body>
                    <Form.Group controlId="formRoomCode">
                    <Form.Label>Room Code</Form.Label>
                    <Form.Control type="text" placeholder="Enter room code" name="room" required />
                    </Form.Group>
                    <Form.Group controlId="formKey">
                    <Form.Label>Key</Form.Label>
                    <Form.Control type="text" placeholder="Enter key" name="key" required />
                    </Form.Group>
                    <span className="text-warning text-right">LETTERS & NUMBERS ONLY</span><br />
                    <span className="text-warning text-end">CODES & KEYS HAVE 6-CHARACTERS</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" type="submit">Join Room</Button>
                </Modal.Footer>
                </Form>
            </Modal>
    </>
  );
};

export default GalleryComponent;
