// Enhanced Lightbox Feature in GalleryComponent for Individual Image Display with Film Roll Navigation
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'react-responsive-modal/styles.css';
import { Modal as ResponsiveModal } from 'react-responsive-modal';

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [currentUserSyncIds, setCurrentUserSyncIds] = useState([]);

  useEffect(() => {
    const fetchUserImages = async () => {
      const userId = firebase.auth().currentUser.uid;
      const snapshot = await firebase.database().ref('photos').orderByChild('uid').equalTo(userId).once('value');
      const data = snapshot.val();
      if (data) {
        const userImages = Object.values(data);
        const syncIds = [...new Set(userImages.map(image => image.syncId))];
        setCurrentUserSyncIds(syncIds);
      }
    };

    fetchUserImages();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      if (currentUserSyncIds.length > 0) {
        const snapshot = await firebase.database().ref('photos').orderByChild('syncId').once('value');
        const allData = snapshot.val();
        if (allData) {
          const filteredImages = Object.entries(allData)
            .filter(([key, value]) => currentUserSyncIds.includes(value.syncId))
            .map(([key, value]) => ({ key, ...value }));
          setImages(filteredImages);
        }
      }
    };

    fetchImages();
  }, [currentUserSyncIds]);

  const openLightbox = (syncId) => {
    const relatedImages = images.filter(image => image.syncId === syncId);
    setLightboxImages(relatedImages);
    setSelectedImage(relatedImages[0]?.url);
    setShowLightbox(true);
  };

  const selectImage = (url) => {
    setSelectedImage(url);
  };

  return (
    <Container>
      <Row>
        {currentUserSyncIds.map(syncId => {
          const image = images.find(img => img.syncId === syncId);
          return (
            <Col key={syncId} md={3} className="mb-3">
              <img 
                src={image?.url} 
                alt={`img-${syncId}`} 
                className="img-fluid" 
                onClick={() => openLightbox(syncId)}
                style={{ cursor: 'pointer' }}
              />
            </Col>
          );
        })}
      </Row>
      <ResponsiveModal open={showLightbox} onClose={() => setShowLightbox(false)} center closeOnEsc closeOnOverlayClick>
        <img src={selectedImage} alt="Selected" style={{ maxHeight: '80vh', maxWidth: '100%' }} />
        <div style={{ display: 'flex', overflowX: 'auto', marginTop: '10px' }}>
          {lightboxImages.map(({ key, url }) => (
            <img 
              key={key} 
              src={url} 
              alt={`img-${key}`} 
              style={{ maxHeight: '100px', maxWidth: '100px', margin: '0 5px', cursor: 'pointer' }} 
              onClick={() => selectImage(url)} 
            />
          ))}
        </div>
      </ResponsiveModal>
    </Container>
  );
};

export default GalleryComponent;
