import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const UserHistoryCards = () => {
  const [roomData, setRoomData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRoom, setCurrentRoom] = useState({});
  const [roomName, setRoomName] = useState('');
  const [roomDescription, setRoomDescription] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState(null);


  const getMostRecentImageUrl = async (roomCode) => {
    try {
      const photosRef = firebase.database().ref('photos').orderByChild('roomCode').equalTo(roomCode);
      const snapshot = await photosRef.limitToLast(1).once('value');
      if (snapshot.exists()) {
        const photos = snapshot.val();
        const mostRecentPhotoKey = Object.keys(photos)[0];
        return {
          url: photos[mostRecentPhotoKey].url,
          timestamp: photos[mostRecentPhotoKey].timestamp,
        };
      }
      return { url: './assets/confTrav.gif', timestamp: null }; // Placeholder values
    } catch (error) {
      console.error('Error fetching most recent image:', error);
      return { url: './assets/confTrav.gif', timestamp: null }; // Error placeholder values
    }
  }; 

  useEffect(() => {
    const uid = firebase.auth().currentUser.uid;
    const roomsRef = firebase.database().ref(`rooms/${uid}`);
    roomsRef.on('value', async (snapshot) => {
      const rooms = snapshot.val();
      if (rooms) {
        const promises = Object.entries(rooms).map(async ([roomCode, details]) => {
          const { url, timestamp } = await getMostRecentImageUrl(roomCode);
          return {
            roomCode,
            key: details.key,
            name: details.name || '',
            description: details.description || '',
            dateCreated: details.dateCreated, // Store raw timestamp for sorting
            dateLastUsed: details.dateLastUsed, // Store raw timestamp for sorting
            imageUrl: url,
            mostRecentImageTimestamp: timestamp,
          };
        });
        let formattedData = await Promise.all(promises);
  
        // Sort rooms by dateLastUsed in descending order
        formattedData.sort((a, b) => b.dateCreated - a.dateCreated);
  
        // Optionally, format the dates for display after sorting
        formattedData = formattedData.map(room => ({
          ...room,
          dateCreated: new Date(room.dateCreated).toLocaleString(),
          dateLastUsed: new Date(room.dateLastUsed).toLocaleString(),
        }));
  
        setRoomData(formattedData);
      } else {
        setRoomData([]);
      }
    });
  
    return () => {
      firebase.database().ref(`rooms/${uid}`).off();
    };
  }, []);
  

  const handleOpenModal = (room) => {
    setCurrentRoom(room);
    setRoomName(room.name);
    setRoomDescription(room.description);
    setShowModal(true);
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();
    const uid = firebase.auth().currentUser.uid;
    await firebase.database().ref(`rooms/${uid}/${currentRoom.roomCode}`)
            .update({ name: roomName, description: roomDescription });
    setShowModal(false);
  };

  // Function to calculate the time difference in days
  const getTimeDifferenceInDays = (timestamp) => {
    const currentTime = new Date();
    const imageTime = new Date(timestamp);
    const difference = currentTime - imageTime;
    return Math.floor(difference / (1000 * 3600 * 24)); // convert milliseconds to days
  };

  const handleOpenModalForDescription = (room) => {
    setCurrentRoom(room);
    setRoomName(room.name);
    setRoomDescription(room.description);
    setShowModal(true);
  };

  const handleDeleteRoom = async () => {
    if (roomToDelete) {
      const uid = firebase.auth().currentUser.uid;
      await firebase.database().ref(`rooms/${uid}/${roomToDelete.roomCode}`).remove();
      setRoomToDelete(null);
      setShowDeleteModal(false);
    }
  };

  const openDeleteModal = (room) => {
    setRoomToDelete(room);
    setShowDeleteModal(true);
  };

  return (
    <>
      <Row>
        {roomData.map(room => (
          <Col md={3} key={room.roomCode}>
            <Card className="mb-3">
              <Card.Header>
                <h3>{room.name || room.roomCode}</h3>
              </Card.Header>
              <Card.Body>
                <Card.Title>{room.description}</Card.Title>
                <Card.Subtitle className="text-muted">{room.dateCreated}</Card.Subtitle>
                <img
                  className="d-block user-select-none"
                  width="100%"
                  height="auto"
                  src={room.imageUrl}
                  style={{ aspectRatio: '4/3' }}
                  alt="Room"
                />
                <Row className="mt-2">
                    <Col>
                        <h6 className="card-text">{room.dateLastUsed}</h6>
                    </Col>
                </Row>
              </Card.Body>
              <Card.Body>
                <Row>
                    <Col>
                        <Button className="px-2 mx-0" variant="success" href={`/splat?room=${room.roomCode}&key=${room.key}`} style={{ width: '100%', textAlign: 'center'}}>View Room</Button>
                    </Col>
                    <Col>
                        <Button className="px-2 mx-0" variant="info" href={`/album?room=${room.roomCode}&key=${room.key}`} style={{ width: '100%', textAlign: 'center'}}>View Album</Button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Button className="px-2 mx-0" variant="warning" onClick={() => handleOpenModal(room)} style={{ width: '100%', textAlign: 'center'}}>Edit Name</Button>
                    </Col>
                    <Col>
                        <Button className="px-2 mx-0" variant="danger" onClick={() => handleOpenModalForDescription(room)} style={{ width: '100%', textAlign: 'center'}}>Edit About</Button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col><Button variant="outline-danger" onClick={() => openDeleteModal(room)} style={{ width: '100%', textAlign: 'center'}}>Delete Room</Button></Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-muted">
                Time since last photo: {room.mostRecentImageTimestamp ? getTimeDifferenceInDays(room.mostRecentImageTimestamp) : 'N/A'} days
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Room Details</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSaveChanges}>
          <Modal.Body>
            <Form.Group controlId="formRoomName">
              <Form.Label>Room Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter room name" 
                name="name" 
                value={roomName}
                onChange={e => setRoomName(e.target.value)} 
              />
            </Form.Group>
            <Form.Group controlId="formRoomDescription">
              <Form.Label>Room Description</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3}
                placeholder="Enter room description" 
                name="description" 
                value={roomDescription}
                onChange={e => setRoomDescription(e.target.value)} 
              />
            </Form.Group>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" type="submit">Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this room? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteRoom}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserHistoryCards;
