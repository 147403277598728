import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import QRCode from 'qrcode.react';
// import './Landing.css';
// import './bootstrap.min.css';
// fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Share from './Share';

const LandingPage = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [roomCode, setRoomCode] = useState('');
    const [key, setKey] = useState('');

    const handleJoinRoom = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        history.push(`/splat?room=${roomCode}&key=${key}`);
    };

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);


    return (
        <div>
            <Container fluid className="hero-section text-light text-center herobg pt-0 px-0 border-bottom border-info border-5 shadow">
                <Container>
                <Row>
                    <Col className="col-12 handsbg mt-0 pt-5 d-none d-lg-block">
                        {/* <img src="./assets/illustrated-hands.png" style={{ width: '100%', }}></img> */}
                        <Row className="mt-5 pt-5">
                        <Col className="col-3 .d-lg-none .d-xl-block mt-5 pt-5">
                            &nbsp;
                        </Col>
                        <Col className="col-12 col-md-6 mt-5 pt-5">
                            <h1 className="mt-5 pt-5 text-dark fw-bold"><strong>Welcome to PartyPics!</strong></h1>
                            {/* 
                            <p>🌟 Snap in Sync with the World! 🌟</p>*/}
                            <h5 className="card bg-warning text-dark px-2 py-1 shadow-sm">Join the revolution of real-time photography!</h5>
                            <h6 className="text-dark fw-bold"><strong>Start by selecting an option below</strong></h6>

                            <div className="action-buttons mb-4">
                                <a href="/splat" className="btn btn-success shadow-sm mx-4 my-2"><strong>Create a New Room</strong></a>
                                <Button variant="info" className="mx-4 my-2 shadow-sm" onClick={handleShowModal}><strong>Join an Existing Room</strong></Button>
                                <a href="/potato" className="btn btn-danger mx-4 my-2 shadow-sm"><strong>Play pHOTo Potato</strong></a>
                                <a href="/retro" className="btn btn-light border border-2 mx-4 my-2 shadow-sm"><strong>Try Retro Camera</strong></a>
                            </div>

                            <h4 className="text-dark">Capture the Moment Together!</h4>
                        </Col>
                        <Col className="col-3 .d-lg-none .d-xl-block">
                            &nbsp;
                        </Col>
                    </Row>
                    </Col>
                </Row>
                </Container>
            </Container>           
                        
            {/* Hero Section */}
            <Container className="hero-section text-dark text-center herobgfaded my-0 py-3 d-block d-lg-none">
                <Row>
                    <Col className="col-3 .d-lg-none .d-xl-block">
                        &nbsp;
                    </Col>
                    <Col className="col-12 col-md-6">
                        <h1>Welcome to PartyPics!</h1>
                        {/* 
                        <p>🌟 Snap in Sync with the World! 🌟</p>*/}
                        <h5 className="card bg-warning text-dark px-2 py-1 shadow-sm">Join the revolution of real-time photography!</h5>
                        <h6>Start by selecting an option below</h6>

                        <div className="action-buttons mb-4">
                            <a href="/splat" className="btn btn-success shadow-sm mx-4 my-2"><strong>Create a New Room</strong></a>
                            <Button variant="info" className="mx-4 my-2 shadow-sm btn btn-info" onClick={handleShowModal}><strong>Join an Existing Room</strong></Button>
                            <a href="/potato" className="btn btn-danger mx-4 my-2 shadow-sm"><strong>Play pHOTo Potato</strong></a>
                            <a href="/retro" className="btn btn-light border border-2 mx-4 my-2 shadow-sm"><strong>Try Retro Camera</strong></a>
                        </div>

                        <h4>Capture the Moment Together!</h4>
                    </Col>
                    <Col className="col-3 .d-lg-none .d-xl-block">
                        &nbsp;
                    </Col>
                </Row>
            </Container>

            {/* New Feature Test*/}
            <Container className="features-section my-0 py-1">
                <Row className="text-center">
                    <Col className="col-12 col-md-4 px-2 my-3">
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="feature-icon">
                                    {/* 📸 */}
                                    <h1><i className="fa-duotone fa-camera-retro text-success"></i></h1>
                                </div>
                                <div className="feature-title my-4">
                                    <h3 className="text-info">Live Camera Feed</h3>
                                </div>
                                <div className="feature-description px-2">
                                    <h5 className="text-dark">Dive into a live stream of moments! Your device's camera is your window to a world of synchronized snapshots.</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="feature-icon">
                                    {/* 🔵 */}
                                    <h1><i className="fa-duotone fa-aperture text-info"></i></h1>
                                </div>
                                <div className="feature-title my-4">
                                    <h3 className="text-success">Click Together with ShutterSync</h3>
                                </div>
                                <div className="feature-description px-2">
                                    <h5 className="text-dark">One button, countless cameras. Tap the vibrant Shutter Sync and capture a unified moment across the globe.</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="feature-icon">
                                    {/* 🔗 */}
                                    <h1><i className="fa-duotone fa-barcode-read text-success"></i></h1>
                                </div>
                                <div className="feature-title text-info my-4">
                                    <h3>Easy Access with a QR Code</h3>
                                </div>
                                <div className="feature-description px-2">
                                    <h5 className="text-dark">A quick scan is all it takes to join our photo family.</h5>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-12 col-md-4 px-2 my-3 border border-3 border-warning bg-info py-2 rounded-3 shadow-lg">
                        <img src="./assets/ppmobprev.png" style={{ width: '100%', }}></img>
                    </Col>
                    <Col className="col-12 col-md-4 px-2 my-3">
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="feature-icon">
                                {/* 🌍 */}
                                <h1><i className="fa-duotone fa-globe text-info"></i></h1>
                            </div>
                            <div className="feature-title my-4">
                                <h3 className="text-success">Count Me Among the Stars</h3>
                            </div>
                            <div className="feature-description px-2">
                                <h5 className="text-dark">See your impact! Our Active User Count shows you're part of a growing community of shutterbugs.</h5>
                            </div>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="feature-icon">
                                {/* 🚦 */}
                                <h1><i className="fa-duotone fa-bullhorn text-success"></i></h1>
                            </div>
                            <div className="feature-title text-info my-4">
                                <h3>Stay Alert with Fun Notifications</h3>
                            </div>
                            <div className="feature-description px-2">
                                <h5 className="text-dark">From emoji signals to countdowns, never miss the perfect shot.</h5>
                            </div>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                            <div className="feature-icon">
                            {/* 💚 */}
                            <h1><i className="fa-duotone fa-traffic-light-go text-success"></i></h1>
                        </div>
                        <div className="feature-title text-success my-4">
                            <h3>Always Connected</h3>
                        </div>
                        <div className="feature-description px-2">
                            <h5 className="text-dark">Green means go! Our Connection Status Indicator keeps you in the loop.</h5>
                        </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                ## Home Page Lower Row
                <Row className="text-center justify-content-evenly">
                    <Col className="col-12 col-md-4 px-2 my-3">
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="card bg-dark text-light px-2 mb-0 shadow">
                                    <div className="card-header text-warning"><h4>Add your friends</h4></div>
                                    <div className="card-body">
                                    <h5 className="card-title">Share this QR Code</h5>
                                    <div className="card-text">
                                    <QRCode 
                                        value={`https://bit.ly/3BIS71b`}
                                        size={240}
                                        bgColor={"#f6f6f6"}
                                        fgColor={"#0b0b0b"}
                                        level={"H"}
                                        includeMargin={true}
                                        imageSettings={{
                                            src: "https://partypics.zip/logo512.png",
                                            x: undefined,
                                            y: undefined,
                                            height: 48,
                                            width: 48,
                                            excavate: true,
                                        }}></QRCode>
                                        <br />
                                        {/* Scan the QR code or enter room and key manually. */}
                                        <h5 className="card-title">Share These Codes</h5>
                                        <Row className="my-2 mx-0 text-center">
                                        <Col className="col-6 mx-0 px-0">
                                        <h5>Room: <span className="text-warning">1IZ4GB</span></h5>
                                        </Col>
                                        <Col className="col-6 mx-0 px-0">
                                        <h5>Key: <span className="text-warning">1EV4SJ</span></h5>
                                        </Col>
                                        </Row>
                                    </div>
                                    </div>
                                    <Button variant="warning" onClick={() => setShowModal(true)} style={{ width: '100%', fontSize: '1.5em', marginBottom: '0.5em'}}>Join an existing Room</Button>
                                </div>
                                <h6 className="text-warning mt-1">this qr, room, and key code are examples</h6>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col className="col-12 col-md-4 px-2 my-3">
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="feature-icon">
                                    <h1><i className="fa-duotone fa-camera-retro text-success"></i></h1>
                                </div>
                                <div className="feature-title my-4">
                                    <h3 className="text-info">Live Camera Feed</h3>
                                </div>
                                <div className="feature-description px-2">
                                    <h5 className="text-dark">Dive into a live stream of moments! Your device's camera is your window to a world of synchronized snapshots.</h5>
                                </div>
                            </Col>
                        </Row>
                    </Col> */}
                    <Col className="col-12 col-md-4 px-2 my-3">
                        <Row className="text-center">
                            <Col className="col-12 px-2 my-4">
                                <div className="card bg-success text-light px-2 mb-0 shadow">
                                    <div className="card-header text-light"><h3>What Is This?</h3></div>
                                    <div className="card-body">
                                    <h5 className="card-title text-dark">How Do I Use this?</h5>
                                    <div className="card-text text-left">
                                        <ol>
                                        <li>Start, Join, or Create a Room</li>
                                        <li>Share the QR or Room Codes</li>
                                        <li>Press the GREEN photo button</li>
                                        <li>Wait for the countdown to finish</li>
                                        <li>See your latest photos below</li>
                                        </ol>
                                    </div>
                                    <h5 className="card-title text-dark">OK, Then What?</h5>
                                    <div className="card-text text-left">
                                        <ol>
                                        <li>Share with your friends</li>
                                        <li>Compare your latest shots</li>
                                        <li>Have some fun with it!</li>
                                        <li>Show support. Buy me a coffee.</li>
                                        </ol>
                                    </div>
                                    <h6 className="card-title mt-1 text-dark">Thanks for checking us out!</h6>
                                    </div>
                                    {/* <Button variant="info" onClick={() => setShowModal(true)} style={{ width: '100%', fontSize: '1.5em', marginBottom: '0.5em'}}>Share With Friends</Button> */}
                                    <Share />
                                </div>
                                <h6 className="text-info mt-1">wow, you read the whole page?!</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Join a Room</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleJoinRoom}>
                    <Modal.Body>
                        <Form.Group controlId="formRoomCode">
                            <Form.Label>Room Code</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter room code" 
                                value={roomCode}
                                onChange={(e) => setRoomCode(e.target.value)} 
                                required 
                            />
                        </Form.Group>
                        <Form.Group controlId="formKey">
                            <Form.Label>Key</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter key" 
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                                required 
                            />
                        </Form.Group>
                        <span className="text-warning text-right">LETTERS & NUMBERS ONLY</span><br />
                        <span className="text-warning text-end">CODES & KEYS HAVE 6-CHARACTERS</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                        <Button variant="primary" type="submit">Join Room</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

const FeatureBox = ({ icon, title, description }) => (
    <Col md={4} className="feature-box">
        <span className="feature-icon">{icon}</span>
        <h3>{title}</h3>
        <p>{description}</p>
    </Col>
);

export default LandingPage;
