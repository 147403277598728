import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Pagination, Modal, Button } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const GalleryComponent = () => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lightbox, setLightbox] = useState({ open: false, image: null, imageKey: null });
  const imagesPerPage = 24;

  useEffect(() => {
    const fetchImages = async () => {
      const uid = firebase.auth().currentUser.uid;
      const snapshot = await firebase.database().ref('photos').orderByChild('uid').equalTo(uid).once('value');
      const data = snapshot.val();
      if (data) {
        const loadedImages = Object.entries(data)
                                  .map(([key, value]) => ({ key, url: value.url, timestamp: value.timestamp }))
                                  .sort((a, b) => b.timestamp - a.timestamp); // Sort images by newest first
        setImages(loadedImages);
      }
    };

    fetchImages();
  }, []);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(images.length / imagesPerPage); i++) {
    pageNumbers.push(i);
  }

  const openLightbox = (image, key) => setLightbox({ open: true, image, imageKey: key });

  const closeLightbox = () => setLightbox({ open: false, image: null, imageKey: null });

  const deleteImage = async () => {
    await firebase.database().ref(`photos/${lightbox.imageKey}`).remove();
    setImages(images.filter(image => image.key !== lightbox.imageKey));
    closeLightbox();
  };

  return (
    <Container>
      <Row>
        {currentImages.map(({ key, url }) => (
          <Col key={key} xs={3} md={2} className="mb-3">
            <img src={url} alt={`img-${key}`} className="img-fluid" style={{ cursor: 'pointer', aspectRatio: '4/3' }} onClick={() => openLightbox(url, key)} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <Pagination>
            {pageNumbers.map(number => (
              <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                {number}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
      <Modal show={lightbox.open} onHide={closeLightbox} centered>
        <Modal.Body onClick={e => e.stopPropagation()}>
          <img src={lightbox.image} alt="lightbox" className="img-fluid" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeLightbox}>Close</Button>
          <Button variant="danger" onClick={deleteImage}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default GalleryComponent;
