import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Col, InputGroup, FormControl, Button, Row } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const contactRef = firebase.database().ref('contacts');

        contactRef.on('value', (snapshot) => {
            const contacts = snapshot.val();
            const contactList = [];

            for (let id in contacts) {
                contactList.push({ id, ...contacts[id] });
            }

            setContacts(contactList);
        });
    }, []);

    const mailtoLink = (email, name) => {
        const subject = encodeURIComponent("Response to Your Contact Message");
        const body = encodeURIComponent(`Dear ${name},\n\n[Your response here]\n\nBest regards,`);
        return `mailto:${email}?subject=${subject}&body=${body}`;
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleCheckboxChange = (id) => {
        const updatedContacts = contacts.map(contact => {
            if (contact.id === id) {
                return { ...contact, replied: !contact.replied };
            }
            return contact;
        });

        setContacts(updatedContacts);
    };

    const filteredContacts = contacts.filter(contact => 
        contact.email.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Container className="my-5">
            <Row>
                <Col md={12}>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Search by email"
                            onChange={handleSearchChange}
                        />
                        <Button variant="outline-secondary" id="button-addon2">
                            Search
                        </Button>
                    </InputGroup>
                    <Table striped bordered hover responsive className="shadow">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Message</th>
                                <th>Received At</th>
                                <th>Respond</th>
                                <th>Replied</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredContacts.map((contact) => (
                                <tr key={contact.id}>
                                    <td>{contact.name}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.message}</td>
                                    <td>{new Date(contact.timestamp).toLocaleString()}</td>
                                    <td>
                                        <a href={mailtoLink(contact.email, contact.name)}>Respond</a>
                                    </td>
                                    <td>
                                        <Form.Check 
                                            type="checkbox" 
                                            checked={contact.replied || false}
                                            onChange={() => handleCheckboxChange(contact.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactList;
