// About Us Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutUs = () => (
    <Container className="my-5">
        <Row>
            <Col md={12}>
                <h2 className="text-center text-info">About Us</h2>
                <p className="text-dark">
                    At PartyPics, we believe in capturing and sharing moments in real-time. Our platform allows users to create, join, and share their experiences through live photography. Our mission is to connect people globally through the magic of shared moments.
                </p>
                <p className="text-dark">
                    Founded in 2023, PartyPics has grown into a community where moments are not just captured but also shared and celebrated collectively. Our unique technology enables users to sync their cameras and capture moments simultaneously, creating a unified experience.
                </p>
                <p className="text-dark">
                    We're dedicated to providing an inclusive, fun, and safe platform for all to enjoy the beauty of collective experiences. Welcome to PartyPics, where every picture tells a story.
                </p>
            </Col>
        </Row>
    </Container>
);

export default AboutUs;