import React, { useState, useContext, useEffect } from 'react';
import { Card, Button, Container, Row, Col, Modal, ListGroup, Table } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { FaTimes } from 'react-icons/fa';

// Stripe promise
const stripePromise = loadStripe("YOUR_STRIPE_PUBLIC_KEY");

const tiers = [
    { id: 1, title: "Tier 1", price: "$0.99 USD", duration: "24 hours", features: ["Save images for 24 hours"] },
    { id: 2, title: "Tier 2", price: "$1.99 USD", duration: "7 days", features: ["Save images for 7 days"] },
    { id: 3, title: "Tier 3", price: "$4.99 USD", duration: "30 days", features: ["Save images for 30 days"] }
];

const addons = [
    { id: 1, title: "Add-on 1", price: "$0.99 USD", description: "Download Digital Copy of Images" },
    { id: 2, title: "Add-on 2", price: "$0.99 USD", description: "Live Image Wall w/ QR Code" },
    { id: 3, title: "Add-on 3", price: "$0.99 USD", description: "Custom Room Page/App Design" },
    { id: 4, title: "Add-on 4", price: "$0.99 USD", description: "Custom Text or Image Banner" }
];

// Helper to extract numeric price from string
const extractPrice = (priceStr) => parseFloat(priceStr.replace(/[^0-9.]/g, ''));

// Cart Context
const CartContext = React.createContext();

// CartProvider Component
const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    setCart([...cart, item]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart }}>
      {children}
    </CartContext.Provider>
  );

  const removeFromCart = (index) => {
    setCart(cart.filter((_, i) => i !== index));
  };

  return (
      <CartContext.Provider value={{ cart, addToCart, removeFromCart }}>
          {children}
      </CartContext.Provider>
  );
};

const PricingPage = () => {
  const [showCart, setShowCart] = useState(false);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [rooms, setRooms] = useState([]);
  const { cart, addToCart, removeFromCart } = useContext(CartContext);

  const total = cart.reduce((acc, item) => acc + extractPrice(item.price), 0);

  const handleRemoveFromCart = (index) => {
      // Confirmation logic (e.g., using a modal) can be added here
      removeFromCart(index);
  };

  // Fetch rooms for the current user
  useEffect(() => {
    const uid = firebase.auth().currentUser.uid;
    const roomsRef = firebase.database().ref(`rooms/${uid}`);
    roomsRef.on('value', snapshot => {
      const roomsData = snapshot.val();
      if (roomsData) {
        setRooms(Object.entries(roomsData).map(([key, value]) => ({ roomCode: key, ...value })));
      }
    });
  
    return () => roomsRef.off();
  }, []);

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    setShowRoomModal(true);
  };

  const handleAddRoomToCart = (room) => {
    if (selectedProduct && room) {
      const transaction = {
        roomCode: room.roomCode,
        key: room.key,
        productID: selectedProduct.id,
        dateTime: new Date().toISOString()
      };

      // Add transaction to cart and Firebase database
      addToCart(transaction);
      const uid = firebase.auth().currentUser.uid;
      firebase.database().ref(`shop/${uid}`).push(transaction);
    }
    setShowRoomModal(false);
  };

  const handleAddToCart = (item) => {
    addToCart({ ...item, title: item.title || selectedProduct.title, price: item.price || selectedProduct.price });
};

  const handleCheckout = () => {
      // Handle checkout logic
  };

  return (
        <Container>
            <Row className="text-dark mt-3" >
                <h1>Pricing</h1>
                <p>Select a tier and add-ons for your event</p>

                {/* Display Tiers */}
                {tiers.map(tier => (
                    <Col key={tier.id} md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Title>{tier.title}</Card.Title>
                                <Card.Subtitle>{tier.price}</Card.Subtitle>
                                <ul>
                                    {tier.features.map((feature, index) => <li key={index}>{feature}</li>)}
                                </ul>
                                <Button variant="primary" onClick={() => handleSelectProduct(tier)}>Select {tier.title}</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row className="my-4">
                <h2>Add-Ons</h2>

                {/* Display Add-Ons */}
                {addons.map(addon => (
                    <Col key={addon.id} md={3}>
                        <Card>
                            <Card.Body>
                                <Card.Title>{addon.title}</Card.Title>
                                <Card.Text>{addon.description}</Card.Text>
                                <Card.Text>{addon.price}</Card.Text>
                                <Button variant="secondary" onClick={() => handleAddToCart(addon)}>Add to Cart</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Button variant="warning" onClick={() => setShowCart(true)}>View Cart</Button>

            {/* Updated Shopping Cart Modal */}
            <Modal show={showCart} onHide={() => setShowCart(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Shopping Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {cart.map((item, index) => (
                            <ListGroup.Item key={index}>
                                {`${item.title} - ${item.price} - Description: ${item.features || ''} Room Code: ${item.roomCode || ''}`}
                                <FaTimes style={{ cursor: 'pointer', float: 'right' }} onClick={() => handleRemoveFromCart(index)} />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <p>Total: ${total.toFixed(2)} USD</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCart(false)}>Close</Button>
                    <Button variant="primary" onClick={handleCheckout}>Checkout</Button>
                </Modal.Footer>
            </Modal>

            {/* Room Selection Modal */}
            <Modal show={showRoomModal} onHide={() => setShowRoomModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Select a Room</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Room Code</th>
                      <th>Room Name</th>
                      <th>Last Image</th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rooms.map((room) => (
                      <tr key={room.roomCode}>
                        <td>{room.roomCode}</td>
                        <td>{room.name}</td>
                        <td>{/* Image logic here */}</td>
                        <td>
                          <Button variant="primary" onClick={() => handleAddRoomToCart(room)}>Select</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
        </Container>
    );
}

// CheckoutForm Component
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    // Handle payment process
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <Button type="submit" disabled={!stripe}>Pay with Stripe</Button>
    </form>
  );
};

// App Component
const App = () => {
  return (
    <CartProvider>
      <Elements stripe={stripePromise}>
        <PricingPage />
      </Elements>
    </CartProvider>
  );
};

export default App;