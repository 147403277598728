// Updated React Component Code with Enhanced Room and Key Security
import React, { useRef, useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Form, ListGroup, Container } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import Highlights from './SyncHighlight';
import Share from './Share';
// import SyncStream from './SyncStream';
import SyncStream2 from './SyncStream2';
// import RoomTracker from './RoomTracker';

const CameraComponent = () => {
  const videoRef = useRef(null);
  const flashRef = useRef(null);
  const socket = useRef(null);
  const syncStreamRef = useRef(null); // Reference for SyncStream component
  const [roomCode, setRoomCode] = useState('');
  const [key, setKey] = useState('');
  const [syncId, setSyncId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [activeUsers, setActiveUsers] = useState(0);
  const [photoNotification, setPhotoNotification] = useState('📷');
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const albumUrl = `/album?room=${roomCode}&key=${key}`;
  const backUrl = `/splat?room=${roomCode}&key=${key}`;
  const roomType = 'splat';

  useEffect(() => {
    const getMedia = async () => {
      try {
        const constraints = { video: { facingMode: 'environment', width: { ideal: 4096 }, height: { ideal: 2160 } } };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      } catch (err) {
        console.error("Error accessing the device's camera", err);
      }
    };

  getMedia();

  }, [roomCode, key]);

  

  return (
    <div>
        <Container fluid className="my-0 mx-0 px-0 py-0 text-center">
            <Row className="my-0 mx-0 px-0 py-0 text-center">
                <Col className="col-12 px-0 mx-0 py-0 my-0 text-center">
                    <video ref={videoRef} style={{ width: '100%', height: '100%', objectFit: 'cover' }} autoPlay muted playsInline></video>
                </Col>
            </Row>
        </Container>
    </div>
  );
};

export default CameraComponent;