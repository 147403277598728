// Privacy Policy Component
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy = () => (
    <Container className="my-5">
        <Row>
            <Col md={12}>
                <h2 className="text-center text-info">Privacy Policy</h2>
                <p className="text-dark">
                    Your privacy is important to us. It is PartyPics' policy to respect your privacy regarding any information we may collect from you across our website, https://partypics.zip, and other sites we own and operate.
                </p>
                <h4 className="text-dark">1. Information We Collect</h4>
                <p className="text-dark">
                    We collect information to provide better services to all our users. The information we collect, and how that information is used, depends on how you use our services and how you manage your privacy controls.
                </p>
                <h4 className="text-dark">2. Consent</h4>
                <p className="text-dark">
                    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                </p>
                <h4 className="text-dark">3. Information We Share</h4>
                <p className="text-dark">
                    We do not share personal information with companies, organizations, or individuals outside of PartyPics unless one of the following circumstances applies...
                </p>
                <p className="text-dark">
                    [Further details on information sharing coming soon...]
                </p>
                <h4 className="text-dark">4. Security</h4>
                <p className="text-dark">
                    We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold.
                </p>
                <h4 className="text-dark">5. Changes to This Policy</h4>
                <p className="text-dark">
                    Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent.
                </p>
            </Col>
        </Row>
    </Container>
);

export default PrivacyPolicy;
