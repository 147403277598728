// ShareComponent.js
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const ShareComponent = ({ roomCode, keyCode }) => { // Renamed "key" to "keyCode" to avoid conflicts with reserved words
  const [shareData, setShareData] = useState({
    title: document.title,
    text: '',
    url: '',
  });

  useEffect(() => {
    const metaDescription = document.querySelector("meta[name='description']");
    setShareData({
      title: document.title,
      text: metaDescription?.content || '',
      url: `${window.location.origin}${window.location.pathname}?room=${roomCode}&key=${keyCode}`, // Using "keyCode" instead of "key"
    });
  }, [roomCode, keyCode]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      console.log('Share API not supported in this browser');
    }
  };

  return (
    <Button variant="info" onClick={handleShare} style={{ width: '100%', fontSize: '1.5em', marginBottom: '0.5em', marginTop: '0.5em'}}>Share</Button>
  );
};

export default ShareComponent;
